import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
// import 'material-design-icons-iconfont/dist/material-design-icons.css';


Vue.use(Vuetify);

const opts = {
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    disable: true,
    //themes: {
    //  light: {
    //    primary: '#F1BE48',
    //    secondary: 'rgba(0, 0, 0, 0.4)',
    //    accent: '#F1BE48',
    //    error: '#ff0000',
    //  },
    //},
  },

};

export default new Vuetify(opts);

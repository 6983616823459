var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "block-single block-flats" },
    [
      _vm.groups && _vm.groups.length
        ? _c(
            "v-app",
            { ref: "main" },
            [
              _c("v-content", { staticClass: "content" }, [
                _c("div", { staticClass: "main-title" }, [_vm._v("Квартиры")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "spaces" },
                  [
                    _c(
                      "v-expansion-panels",
                      {
                        attrs: {
                          accordion: _vm.accordion,
                          readonly: _vm.readonly
                        },
                        model: {
                          value: _vm.panel,
                          callback: function($$v) {
                            _vm.panel = $$v
                          },
                          expression: "panel"
                        }
                      },
                      _vm._l(_vm.groups, function(item, key) {
                        return _c(
                          "v-expansion-panel",
                          {
                            key: key,
                            staticClass: "spaces-groups",
                            on: {
                              click: function($event) {
                                return _vm.toggleTab(item)
                              }
                            }
                          },
                          [
                            _c(
                              "v-expansion-panel-header",
                              {
                                ref: "space_group",
                                refInFor: true,
                                staticClass: "spaces-group"
                              },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "spaces-group__title" },
                                      [
                                        item.RoomCountListing
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(item.RoomCountListing) +
                                                  "-комнатные"
                                              )
                                            ])
                                          : _c("span", [_vm._v("Другие")])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "spaces-group__label minarea"
                                      },
                                      [
                                        _vm._v(
                                          "от " +
                                            _vm._s(item.MinAreaGeneral) +
                                            " м2"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "spaces-group__label price"
                                      },
                                      [
                                        _vm._v(
                                          "от " +
                                            _vm._s(
                                              _vm._f("price")(
                                                item.MinPriceInNationalCurrency
                                              )
                                            ) +
                                            " ₴"
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass:
                                          "spaces-group__label price-full"
                                      },
                                      [
                                        _vm._v(
                                          "от " +
                                            _vm._s(
                                              _vm._f("price")(
                                                item.MinPriceInNationalCurrencyByM
                                              )
                                            ) +
                                            "\n                                                              грн/м2\n                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { staticClass: "spaces-group__areas" },
                                      [
                                        _vm._v(
                                          _vm._s(item.ListingCount) + " квартир"
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-expansion-panel-content", [
                              _vm.items.length
                                ? _c(
                                    "div",
                                    [
                                      _vm.items.length
                                        ? _c("v-data-table", {
                                            staticClass: "cc-table",
                                            attrs: {
                                              loading: _vm.loading,
                                              "loading-text":
                                                "Loading... Please wait",
                                              headers: _vm.headers,
                                              "items-per-page": _vm.pageCount,
                                              "custom-sort": _vm.sortSpaces,
                                              "sort-by": _vm.sortField,
                                              "sort-desc": _vm.sortDesc,
                                              "header-props": _vm.headerProps,
                                              "hide-default-footer": "",
                                              items: _vm.items
                                            },
                                            on: {
                                              "update:sortBy": function(
                                                $event
                                              ) {
                                                _vm.sortField = $event
                                              },
                                              "update:sort-by": function(
                                                $event
                                              ) {
                                                _vm.sortField = $event
                                              },
                                              "update:sortDesc": function(
                                                $event
                                              ) {
                                                _vm.sortDesc = $event
                                              },
                                              "update:sort-desc": function(
                                                $event
                                              ) {
                                                _vm.sortDesc = $event
                                              }
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item.Photo",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "table-photo__link",
                                                          attrs: {
                                                            href:
                                                              item.Photo
                                                                .Original,
                                                            "data-fancybox": ""
                                                          }
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "table-photo__img",
                                                            attrs: {
                                                              src:
                                                                item.Photo.Big
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.Url",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "table-more",
                                                          attrs: {
                                                            href: item.Url
                                                          }
                                                        },
                                                        [_vm._v("Подробнее")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("v-pagination", {
                                        staticClass: "cc-pagination",
                                        attrs: {
                                          light: "",
                                          "total-visible": 5,
                                          length: _vm.maxPage
                                        },
                                        model: {
                                          value: _vm.page,
                                          callback: function($$v) {
                                            _vm.page = $$v
                                          },
                                          expression: "page"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
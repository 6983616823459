<template>
  <div>
    <v-app class="search__container rel">
      <v-content>
        <!--      <div class="hero__search">-->
        <ul class="hero__search-nav nav-tabs">
          <li class="tab pointer"
              v-for="tab in tabs"
              :class="{active: tab === transaction}"
              :key="tab.id"
              @click="transaction = tab">
            {{ tab.name }}
          </li>
        </ul>
        <div class="hero__search-tab tab-content">
          <div class="rel">
            <form class="hero__search-form form" :action="'/listing/list/?RealtyType=' + models.type"
                  method="post">
              <input id="formId" name="formId" type="hidden" value="RealtyFilter">
              <input type="hidden" name="region" :value="selectedIds.regionId">
              <input type="hidden" name="districtIds" :value="selectedIds.districtIds.join(',')">
              <input type="hidden" name="subdistrictIds" :value="selectedIds.subDistrictIds.join(',')">
              <input type="hidden" name="Transaction" :value="transaction.slug">

              <input type="hidden" :value="yandex.Region" id="YandexRegion" name="YandexRegion">
              <input type="hidden" :value="yandex.District" id="YandexDistrict" name="YandexDistrict">
              <input type="hidden" :value="yandex.SubDistrict" id="YandexSubDistrict" name="YandexSubDistrict">
              <input type="hidden" :value="yandex.Street" id="YandexStreet" name="YandexStreet">
              <input type="hidden" :value="yandex.Building" id="YandexBuilderNumber"
                     name="YandexBuilderNumber">
              <input type="hidden" name="SearchString" :value="SearchString" id="SearchString">

              <div ref="fieldSelect">
                <v-select class="custom-select" :attach="$refs.fieldSelect"
                          label="Тип недвижимости"
                          :items="realtyTypes" v-model="models.type"></v-select>
              </div>

              <div>
                <v-tooltip absolute bottom
                           max-width="340"
                           content-class="select-tooltip">
                  <template v-slot:activator="{ on }">
                    <div class="pointer" @click="showPopup()" v-on="on">
                      <v-text-field name="" readonly class="custom-input pointer"
                                    label="Город, район, микрорайон" v-model="models.city"></v-text-field>
                    </div>
                  </template>
                  <span>{{ tooltip }}</span>
                </v-tooltip>
              </div>

              <div>
                <address-search></address-search>
              </div>
              <div class="form__footer">
                <button type="submit" class="btn btn-main">Найти</button>
              </div>
            </form>
          </div>

        </div>
        <!--      </div>-->
      </v-content>
    </v-app>

  </div>
</template>

<script>
  import AddressSearch from "../../components/autocompletes/addressSearch";

  export default {
    name: "homeSearch",
    components: { AddressSearch },
    data: () => ({
      tabs: [
        { id: 4, slug: 'prodazha', name: 'Купить' },
        { id: 3, slug: 'arenda', name: 'Снять' },
        { id: 6, slug: 'posutochnaya_arenda', name: 'Посуточно' },
      ],
      transaction: false,
      realtyTypes: [],
      models: {
        city: '',
        type: '',
        search: false,
      },
      example: 'Например, Дарницкий район',
    }),
    computed: {
      selectedIds() {
        return this.$store.state.selected;
      },
      SearchString() {
        return this.$store.state.SearchString;
      },
      countryCode() {
        return this.$store.state.countryCode;
      },
      objects() {
        return this.$store.state.locationObjects;
      },
      yandex() {
        return this.$store.state.yandex;
      },
      tooltip() {
        let objects = [];
        let districtIds = this.selectedIds.districtIds;
        let subDistrictIds = this.selectedIds.subDistrictIds;

        if (districtIds.length) {
          objects = objects.concat(
            this.objects.cityDistricts.filter(i => districtIds.includes(i.Id)).map(i => i.Name),
            this.objects.districts.filter(i => districtIds.includes(i.Id)).map(i => i.Name),
          );
        }

        if (subDistrictIds.length) {
          objects = objects.concat(
            this.objects.cities.filter(i => subDistrictIds.includes(i.Id)).map(i => i.Name),
            this.objects.SubDistricts.filter(i => subDistrictIds.includes(i.Id)).map(i => i.Name),
          );
        }


        let tooltip = objects.join(', ');
        if (tooltip.length) {
          this.models.city = tooltip;
          return tooltip;
        } else {
          let name = this.selectedIds.region.Name;
          this.models.city = name;
          return name;
        }
      }
    },
    mounted() {
      this.transaction = this.tabs[0];
      // this.getTypes();
    },
    watch: {
      transaction(val) {
        if(val) {
          this.getTypes();
        }
      }
    },
    methods: {
      getTypes() {
        let data = {
          apiMethod: 'realty/gettypes',
          body: {
            purpose: 1,
            transactionTypeId: this.transaction.id,
          },
        };

        this.$store.dispatch('sendRequest', data).then((res) => {
          this.realtyTypes = res.data.map(i => {
            return {
              text: i.Name,
              value: i.Id,
            }
          });

          if (this.realtyTypes.length) {
            this.models.type = this.realtyTypes[0].value;
          }
        });
      },
      showPopup() {
        this.$store.commit('setter', {
          showPopup: true,
        });
      },
    },
  }
</script>

<style lang="scss">
  @import '../../../../../css_scss/scss/setup';

  .search__container {
    background: transparent !important;
  }

  .pointer {
    cursor: pointer;
  }

  .v-application {
    background: transparent !important;
  }

  .v-application--wrap {
    min-height: unset !important;
  }
  .v-tooltip.v-tooltip--bottom.v-tooltip--attached {
    @include tablet {
      display: none;
    }
  }
</style>

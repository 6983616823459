import Vue from 'vue';

Array.prototype.sortAB = function () {
  return this.sort((a, b) => {
    return a - b
  });
};

String.prototype.firstLetterUp = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};


Vue.mixin({
  data() {
    return {
      erMessages: {
        required: 'Это поле обьязательно ',
        minLength: 'Поле должно содержать минимум ',
        email: 'Поле "Email" должно иметь соответствующий формат',
        withoutUrls: 'Текст не должен содержать ссылки',
      },
    };
  },
  methods: {
    convertStringToDate(string) {
      return new Date(this.stringToIso(string))
    },
    stringToIso(string) {
      let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;

      return string.replace(pattern, '$3-$2-$1')
    },
    currentPath() {
      return location.pathname;
    },
    parseGetParams(get_param) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(get_param);
    },
    firstUpper(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatPrice(number) {
      number = parseInt(number);

      return number.toLocaleString('ru-RU');
    },
    getErrorMessage(model) {
      if (model.required === false) {
        return this.erMessages.required;
      } else if (model.email === false) {
        return this.erMessages.email;
      } else if (model.minLength === false) {
        return this.erMessages.minLength + model.$params.minLength.min + ' символов';
      } else if (model.withoutUrls === false) {
        return this.erMessages.withoutUrls;
      }
    },
  },
  filters: {
    price: function (value) {
      // if (typeof value !== 'number') {
      value = parseInt(value);
      // }

      return Number((value).toFixed(1)).toLocaleString('ru-RU');
    },
  },
});

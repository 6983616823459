var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.statistic && _vm.statistic.length
    ? _c("div", { staticClass: "block-single block-dynamics" }, [
        _c("div", { staticClass: "title h3" }, [_vm._v("Динамика цены")]),
        _vm._v(" "),
        _c("div", { staticClass: "block-dynamics__chart" }, [
          _c("div", { ref: "graph", staticClass: "graph" })
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
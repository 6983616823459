<template>
  <div class="popup-wrapper">
    <transition name="fade">
      <div class="vue-popup__overlay" @click.self="cancel" v-if="showPopup">
        <div class="vue-popup popup popup--request" @click.self="cancel">
          <div class="popup__block">
            <button class="popup__close" @click="togglePopup"></button>

            <div class="popup__content" v-if="message">
              {{ message }}
            </div>

            <div v-if="confirmMessage">
              <div class="popup__content">
                {{ confirmMessage }}
              </div>

              <div class="popup__footer">
                <button type="button" @click="affirm" class="feedback__submit">Согласен</button>

                <button type="button" @click="cancel" class="feedback__submit">Отменить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div v-html="form" v-if="form" ref="formWrapper"></div>
  </div>
</template>

<script>
  import * as qs from "qs";
  import { sprintf } from 'sprintf-js';

  // 0: {Count: 30, Period: 0, ServiceId: "2", IsTop10: false}
  // 1: {Count: 30, Period: 0, ServiceId: "3", IsTop10: false}
  // 2: {Count: 1000, Period: 0, ServiceId: "4", IsTop10: false}
  // 3: {Count: 0, Period: 1, ServiceId: "5", IsTop10: false, DateStart: "2020-07-24T21:00:00.000Z"}
  // 4: {Count: 0, Period: 0, ServiceId: 6}
  // 5: {Count: 1, Period: 0, ServiceId: 9, IsTop10: false}

  export default {
    name: "popupServices",
    computed: {
      globalVars() {
        return this.$store.state.globalVars;
      },
    },
    data() {
      let args = qs.parse(window.location.search.slice(1));
      // console.log('query args', args);

      return {
        showPopup: false,
        message: false,
        activePackage: false,
        process: false,
        confirm: false,
        confirmMessage: false,
        confirmMessages: {
          pay: 'На Вашем балансе не достаточно средств, пополните баланс. Если хотите оплатить счет прямо сейчас?',
          dep: 'С Вашего текущего баланса будет снято %s %s и потрачен депозит услуг. Вы согласны?',
          bal: 'С Вашего текущего баланса будет снято %s %s Вы согласны?',
        },
        default: {
          base: {
            countryId: 1,
            entityId: args['entityId'] || 0,
            entityTypeId: args['entityTypeId'] || 1,
          },
          package: {
            Count: 1,
            Period: 0,
            ServiceId: 6,
          },
          premium: {
            Count: 30,
            Period: 0,
            ServiceId: "2",
            IsTop10: false
          },
          hot: {
            Count: 30,
            Period: 0,
            ServiceId: "3",
            IsTop10: false
          },
          syndication: {
            Count: 0,
            DateStart: "2020-07-24T21:00:00.000Z",
            IsTop10: false,
            Period: 1,
            ServiceId: "5",
          },
          emails: {
            // Count: 1000,
            Count: 5000,
            IsTop10: false,
            Period: 0,
            ServiceId: "4",
          },
          agencies: {
            Count: 1,
            IsTop10: false,
            Period: 0,
            ServiceId: 9,
          }
        },
        list: {},
        lastKey: '',
        lastElem: {},
        prices: [],
        total: 0,
        activeClass: 'package--chosen',
        isRedirect: true,
        Balance: false,
        Order: false,

        addUrl: '/WebServices/Payment.asmx/AddServiceV2',
        saveUrl: '/WebServices/Payment.asmx/PaymentSave',
        payUrl: '/payment/arrivalbybill/',
        redirectUrl: '/userprofile/mylistings/',

        elements: {
          packages: [],
          services: [],
          others: [],
          priceTotal: {},
          submit: {},
          deposit: false,
        },
        form: false,
        user: false,
      }
    },
    watch: {
      showPopup(val) {
        if (val) {
          document.body.classList.add('overflow-hidden');
        } else {
          document.body.classList.remove('overflow-hidden');

          this.message = false;
          this.confirmMessage = false;
        }
      },
    },
    mounted() {
      this.setElements();
      this.setEvents();
      this.setUser();
    },
    methods: {
      setUser() {
        let userMenu = document.querySelector('.userMenu .user-name');

        if (userMenu) {
          this.user = userMenu.textContent.trim();
        }
      },
      togglePopup() {
        this.showPopup = !this.showPopup;
      },
      getElementFromEvent(e, elem) {
        let target = e.target;

        if ( target.tagName === elem.toUpperCase() ) {
          return target;
        } else {
          return target.closest(elem);
        }
      },
      getPathElement(e, cls) {
        let path = e.path || e.composedPath();

        return path.find(e => e.classList.contains(cls));
      },
      toggleClass(el, cls) {
        if (el.classList.contains(cls)) {
          el.classList.toggle(cls)
        }
      },
      setElements() {
        let packages = document.querySelectorAll('.services__packages .package__submit');
        let services = document.querySelectorAll('.services__other input[type=checkbox]');
        let others = document.querySelectorAll('.other-service');

        this.elements.priceTotal = document.querySelector('[data-id=total]');
        this.elements.submit = document.querySelector('.checkout-submit button');
        this.elements.deposit = document.querySelector('[data-id=dep]');

        this.addElements({
          packages: packages,
          services: services,
          others: others,
        })
      },
      addElements(obj) {
        Object.entries(obj).forEach(([key, value]) => {
          Object.values(value).forEach(item => {
            this.elements[key].push(item);
          });
        });
      },
      setEvents() {
        this.elements.packages.forEach(item => {
          // item.addEventListener('click', e => this.choosePackage(e));

          let parent = item.closest('.package');
          parent.addEventListener('click', e => this.choosePackage(e));
        });

        this.elements.services.forEach(item=>{
          item.addEventListener('change', e => this.changeServices(e));
        });

        this.elements.others.forEach(item=>{
          item.addEventListener('click', e => this.changeCheckbox(e));
        });

        this.elements.submit.addEventListener('click', e => this.submit(e));
      },
      changeCheckbox(e) {
        // console.log('changeCheckbox');
        let el = this.getPathElement(e, 'other-service'),
          check = el.querySelector('input[type=checkbox]');

        el.classList.toggle('other-service--chosen');
        check.click();
      },
      changeServices(e) {
        e.stopPropagation();
        e.preventDefault();
        // console.log('changeServices');

        if (this.process) {
          return;
        }

        let elem = e.target,
          id = parseInt(elem.dataset.id),
          type = elem.dataset.type;

        let parent = elem.closest('.other-service');
        parent.classList.toggle('other-service--chosen');

        if (e.target.checked) {
          // console.log('checked');
          let def = this.default[type];
          def.ServiceId = id;

          this.list[type] = def;
          this.lastKey = type;
          this.lastElem = elem;

          this.send();
        } else {
          if (this.list[type]) {
            delete this.list[type];
          }

          parent.classList.remove('other-service--chosen');
          this.send();
        }
      },
      choosePackage(e) {
        let pack = this.getPathElement(e, 'package'),
          btn = pack.querySelector('button'),
          id = parseInt(btn.dataset.id);

        if (this.process) {
          return;
        }

        if (!pack.classList.contains(this.activeClass)) {
          this.changeActivePackage(pack);

          let def = this.default.package;
          def.ServiceId = id;

          this.activePackage = def;

          this.list.package = def;
          this.lastKey = 'package';
          this.lastElem = pack;

          this.send();
        } else {
          this.activePackage = false;
          delete this.list.package;

          pack.classList.remove(this.activeClass);
          this.send();
        }
      },
      changeActivePackage(pack) {
        this.elements.packages.forEach(item=>{
          let p = item.closest('.package');
          p.classList.remove(this.activeClass);
        });

        pack.classList.add(this.activeClass);
      },
      removeLast() {
        delete this.list[ this.lastKey ];

        if ( this.lastElem.tagName === 'INPUT' ) {
          this.lastElem.checked = false;
        } else {
          this.lastElem.classList.remove( this.activeClass );
        }

        // console.log(this.lastElem);
        let parent = this.lastElem.closest('.other-service');
        parent.classList.remove('other-service--chosen');
      },
      send() {
        let body = this.default.base;
        body.list = Object.values(this.list);

        let data = {
          url: this.addUrl,
          method: 'post',
          type: 'json',
          body: body,
        };

        this.process = true;
        this.$store.dispatch('sendRequest', data).then((response) => {
          // console.log('services', response);

          let res = response.data.d;
          if (res && !res.Mess) {
            this.prices = res.ServicePrices;
            this.Balance = res;

            this.updatePrice(res.ToPay);
          } else if(res) {
            this.message = res.Mess;

            this.showPopup = true;
            this.removeLast();
          }

          this.process = false;
        });
      },
      updatePrice(price) {
        this.total = price;

        this.elements.priceTotal.innerText = price;
      },
      submit(e = false) {
        if(e) {
          e.preventDefault();
        }

        if (this.process) {
          return false;
        }

        let body = this.default.base;
        body.list = Object.values(this.list);

        this.process = true;
        if (this.Balance && body.list.length > 0) {
          if (!this.Balance.IsAdmin && !this.confirm) {
            // console.log('confirm');

            if (this.Balance.Cost > 0 && this.Balance.Balance < this.Balance.Cost) {
              // console.log('pay');
              this.confirmMessage = this.confirmMessages.pay;
            // } else if (this.Balance.Balance > 0 && isDep) {
            } else if (this.Balance.Balance > 0) {
              // console.log('dep');
              this.confirmMessage = sprintf(this.confirmMessages.dep, Math.min(this.Balance.Cost, this.Balance.Balance).toFixed(2), this.globalVars.CountryCurrencySign);
            } else if (this.Balance.Balance > 0) {
              // console.log('bal');
              this.confirmMessage = sprintf(this.confirmMessages.bal, Math.min(this.Balance.Cost, this.Balance.Balance).toFixed(2), this.globalVars.CountryCurrencySign);
            }
            this.showPopup = true;
          } else {
            let body = this.default.base;
            body.list = Object.values(this.list);


            body.userName = this.user;

            let data = {
              url: this.saveUrl,
              method: 'post',
              type: 'json',
              body: body,
            };

            this.process = true;
            this.$store.dispatch('sendRequest', data).then((response) => {
              // console.log('services', response);
              this.process = false;

              let res = response.data.d;
              if (res && !res.Mess) {
                if (res.ToPay && res.ToPay > 0 && res.OrderId && res.OrderId > 0) {
                  this.Order = res;
                  this.payBill();
                } else {
                  if (this.isRedirect) {
                    window.location.href = this.redirectUrl;
                  }
                }
              } else if(res) {
                this.message = res.Mess;

                this.showPopup = true;
              }
            });
          }
        }
      },
      payBill() {
        let data = {
          url: this.payUrl,
          method: 'post',
          type: 'json',
          responseType: 'document',
          body: {
            ArrivalAmount: this.numToString(this.Order.ToPay),
            OrderId: this.Order.OrderId,
          }
        };

        this.process = true;
        this.$store.dispatch('sendRequest', data).then((response) => {
          // console.log('payBill', response);
          this.process = false;

          let res = response.data,
            list = res.body.children;

          this.form = list[0].outerHTML;

          this.pushForm();
        });
      },
      pushForm() {
        setTimeout(()=>{
          if (typeof gaqTrackEvent === 'function') {
            gaqTrackEvent('payment', 'gateway', 'start', 0);
          }

          this.$refs.formWrapper.querySelector('form').submit();
        }, 200);
      },
      numToString(num) {
        if (typeof (num) == "number")
          return num.toString().replace(".", ",");
        else {
          return num;
        }
      },
      affirm() {
        this.confirm = true;
        this.showPopup = false;
        this.process = false;
        this.confirmMessage = false;

        this.submit();
      },
      cancel() {
        this.showPopup = false;
        this.process = false;
        this.confirmMessage = false;
      },
    },
  }
</script>

<style lang="scss">
  @import '../../../../../css_scss/scss/setup';

.popup__footer {
  margin-top: 10px;

  @include desktop-up {
    display: flex;
  }

  button {
    transition: color 0.4s;

    @include tablet {
      &:first-child {
        margin-bottom: 10px;
      }
    }

    @include desktop-up {
      &:first-child {
        margin-right: 10px;
      };
    }

    &:hover {
      color: #ffffff;
    }
  }
}
</style>

<template>
  <div>
    <v-autocomplete class="custom-select"
                    v-model="searchObject"
                    :loading="loading"
                    :items="auto"
                    label="Быстрый поиск"
                    :filter="compare"
                    attach
                    hide-no-data
                    return-object
                    :search-input.sync="searchString"></v-autocomplete>
  </div>
</template>

<script>
  export default {
    name: "addressSearch",
    data() {
      return {
        searchObject: false,
        loading: false,
        auto: [],
        search: '',
        yandex: {
          Region: '',
          District: '',
          SubDistrict: '',
          Street: '',
          Building: '',
          Depend: false,
        },
        exclude: {
          russiaFederalArea: "федеральный округ",
          regionalCityDistrict: "городской совет",
        },
        members: [],
        kindTypes: {
          house: true,
          street: true,
          district: true,
          locality: true,
          area: true,
          province: true,
        },
      }
    },
    watch: {
      search(val) {
        if (val && val !== this.searchObject.text && val !== this.region.Name + ', ') {
          this.searchAddress(val);
          this.$store.commit('setter',{SearchString: val});
        }
      },
      searchObject(val) {
        if (val) {
          this.getDetails(val.detail);

          this.search = val.text;
          this.$store.commit('setter',{SearchString: val.text});
        }
      },
    },
    computed: {
      region() {
        return this.$store.state.selected.region;
      },
      searchString: {
        get() {
          if (this.region.Name) {
            this.search = this.region.Name + ', ';
          }

          return this.search;
        },
        set(value) {
          this.search = value;
        }
      },
      geocodeUrl() {
        return this.$store.state.geocodeUrl;
      },
      countryName() {
        return this.$store.state.globalVars.CountryName;
      },
    },
    methods: {
      compare(item, queryText, itemText) {
        let searchText = queryText.replace(/[,\s]+?$/, '').toLowerCase();

        return itemText.toLowerCase().indexOf(searchText) > -1;
      },
      searchAddress(searchString) {
        let data = {
          url: this.geocodeUrl,
          body: {
            text: searchString,
            maxresult: 10,
          },
        };

        let reg = new RegExp(`^${this.countryName}\,?\s?`, 'ig');
        if (searchString.length > 2 && searchString.length % 3 === 0 && !this.loading) {
          this.loading = true;

          this.$store.dispatch('sendRequest', data).then((res) => {
            // console.log('searchAddress', res);

            this.members = this.filterResponse(res.data);
            // console.log('Auto-members', this.members);

            this.auto = this.members.map(i => {
              return {
                text: i.text.trim().replace(reg, '').trim(),
                value: i.text.trim().replace(reg, '').trim(),
                detail: i.AddressDetails.Country,
              }
            });

            // this.auto = this.itemsAutocomplete.filter(e => {
            //   return (e || '').toLowerCase().indexOf((searchString || '').toLowerCase()) > -1
            // });
            // console.log(this.auto);


            this.loading = false;
          });
        }
      },
      filterResponse(response) {
        response = response.response ? response.response : response;

        if (response.GeoObjectCollection && response.GeoObjectCollection.featureMember) {
          let results = response.GeoObjectCollection.featureMember.filter((value) => {
            let data = value.GeoObject.metaDataProperty.GeocoderMetaData;

            return data.kind in this.kindTypes && data.AddressDetails.Country.CountryName === this.countryName
              && data.AddressDetails.Country.Thoroughfare === undefined;
          });

          if (results.length) {
            return results.map(i => i.GeoObject.metaDataProperty.GeocoderMetaData);
          } else {
            return results;
          }
        } else {
          return [];
        }
      },
      getDetails(geolocationResult) {
        for (let key in geolocationResult) {
          if (geolocationResult.hasOwnProperty(key)) {
            if (typeof (geolocationResult[key]) == 'object') {
              return this.getDetails(geolocationResult[key]);
            } else {
              if (key === "AdministrativeAreaName") {
                this.yandex.Region = geolocationResult[key];
              }

              if (key === "SubAdministrativeAreaName") {
                this.yandex.District = geolocationResult[key];
              }

              if (key === "DependentLocalityName") {
                this.yandex.District = geolocationResult[key];
                this.yandex.Depend = true;
              }

              if (key === "LocalityName") {
                this.yandex.SubDistrict = geolocationResult[key];
              }

              if (key === "ThoroughfareName") {
                this.yandex.Street = geolocationResult[key];
              }

              if (key === "PremiseNumber") {
                this.yandex.Building = geolocationResult[key];
              }
            }
          }
        }

        this.yandex.Building = this.getBuildingNumber(this.yandex.Building);


        this.fixResultForUkraine();
        this.fixResultForRussia();

        this.$store.commit('setter', {
          yandex: this.yandex,
        });
      },
      fixResultForRussia() {
        // Федеральный Округ
        if (this.yandex.Region.indexOf(this.exclude.russiaFederalArea) > -1) {
          this.yandex.Region = this.yandex.District;
          this.yandex.District = '';
        }
        // города а-ля Москва и С. питер
        if (this.yandex.Region === this.yandex.SubDistrict && this.yandex.District === '') {
          this.yandex.SubDistrict = this.yandex.District = '';
        }

        if (this.yandex.District.indexOf(this.exclude.regionalCityDistrict) > -1) {
          this.yandex.SubDistrict = '';
        }
      },
      fixResultForUkraine() {
        if (this.yandex.District.indexOf(this.exclude.regionalCityDistrict) > -1) {
          this.yandex.District = '';
        }
        if (this.yandex.Depend && this.yandex.District !== '' && this.yandex.SubDistrict !== ''
          && this.yandex.SubDistrict !== 'Киев' && this.yandex.District !== 'Киев') {
          let tmp = this.yandex.District;
          this.yandex.District = this.yandex.SubDistrict;
          this.yandex.SubDistrict = tmp;
        }
        if (this.yandex.SubDistrict === 'Киев' && this.yandex.District !== 'Киев') {
          this.yandex.SubDistrict = '';
        }
      },
      getBuildingNumber(buildingNumber) {
        if (buildingNumber.length < 1) {
          /* яндекс не дал номер дома - просто отправка всего на сервер */
          return buildingNumber;
        }

        let result = '';
        let results = this.search.match(/(\d{1,4}\/\d{1,4}([а-яА-Я]{1})?|\d{1,4}\/[а-яА-Я]{1}|\d{1,4}-[а-яА-Я]{1}|\d{1,4}[а-яА-Я]{1}|\d{1,4} [а-яА-Я]{1}|\d{1,4})$/g);
        if (results && results.length > 0) {
          result = results[results.length - 1];
        }

        return result;
      }
    },
  }
</script>

<style scoped>

</style>

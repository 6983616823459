<template>
    <div>
  <v-autocomplete class="custom-select"
                  v-model="searchObject"
                  :loading="loading"
                  :items="auto"
                  :name="name"
                  label="Название новостройки"
                  :filter="compare"
                  hide-no-data
                  :menu-props="menuProps"
                  :search-input.sync="searchString"></v-autocomplete>

      <input type="hidden" name="ComplexName" :value="searchObject">
    </div>
</template>

<script>
  export default {
    name: "buildingSearch",
    props: {
      name: String,
      complexName: [Boolean, String],
    },
    data() {
      return {
        searchObject: '',
        loading: false,
        auto: [],
        searchString: '',
        members: [],
        // listUrl: '/WebServices/CascadingDropDownList.asmx/ConstructionComplexGetList',
        listUrl: '/WebServices/CascadingDropDownList.asmx/GetComplexForFilterNew',
        maxResults: 10,
        menuShow: false,
        menuProps: {
          closeOnClick: true,
          // value: this.menuShow,
        },
        searchProgress: true,
      }
    },
    watch: {
      searchString(val) {
        if (val && val !== this.searchObject.text) {
          this.search(val);
        } else {
          this.auto = [];
        }
      },
      complexName(val) {
        if (this.complexName) {
          // this.searchProgress = true;
          setTimeout(() => {
            this.searchString = this.complexName;
          });
        }
      }
    },
    mounted() {
      this.getAllObjects();
    },
    computed: {
      globalVars() {
        return this.$store.state.globalVars;
      },
      complexType() {
        return this.$store.state.complexType;
      },
    },
    methods: {
      compare(item, queryText, itemText) {
        let searchText = queryText.replace(/[,\s]+?$/, '').toLowerCase();

        return itemText.toLowerCase().indexOf(searchText) > -1;
      },
      getAllObjects() {
        let data = {
          url: this.listUrl,
          method: 'post',
          type: 'json',
          body: {
            countryId: "1",
            // complexType: this.complexType,
          },
        };


        if (!this.loading) {
          this.loading = true;
          this.$store.dispatch('sendRequest', data).then((res) => {
            // console.log('getAllObjects', res);
            this.members = res.data.d;

            if (this.members) {
              this.members = this.members.map(i => {
                return {
                  text: this.formatResultText(i),
                  // value: i.Id,
                  value: this.formatResult(i),
                  detail: i,
                }
              });
            }
            // console.log('this.members',this.members);

            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      },
      formatResultText(data) {
        let itemText = data.Name;

        if (data.Region || data.Street) {
          itemText += ' (';
          if (data.Region && data.Region.length) {
            itemText += data.Region;
          }
          if (data.Street && data.Street.length && itemText.indexOf(data.Street) < 0) {
            if (data.Street.indexOf('ул.') < 0 && data.Street.indexOf('улица') < 0 && data.Street.indexOf('с.') < 0 && data.Street.indexOf('село') < 0) {
              itemText += ', ул. ';
            } else {
              itemText += ', ';
            }
            itemText += data.Street;
          }
          itemText += ')';
        }

        return itemText;
      },
      formatResult: function (data) {
        var result = data.Name;
        if (data.Street && data.Street.length && result.indexOf(data.Street) < 0) {
          result += ' ' + data.Street;
        }
        return result;
      },
      search(queryText) {
        // console.log('Search start', queryText);
        if (queryText.length && !this.searchProgress) {
          let auto = [...this.members];
          let searchText = queryText.replace(/[,\s]+?$/, '').toLowerCase();

          this.searchProgress = true;

          let items = auto.filter(i => {
            return i.text.toLowerCase().indexOf(searchText) > -1;
          }).slice(0, this.maxResults);

          // items.unshift({
          //   text: this.searchString,
          //   value: this.searchString,
          // });

          // items = [{
          //   text: this.searchString,
          //   value: this.searchString,
          // }];

          this.auto = items;

          // console.log('found', items);
          this.searchProgress = false;
        } else {
          // this.auto = [];
          this.searchProgress = false;
        }
      }
    },
  }
</script>

<style scoped>

</style>

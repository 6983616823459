<template>
  <div class="block-single block-flats">
    <v-app ref="main" v-if="groups && groups.length">
      <v-content class="content">
        <div class="main-title">Квартиры</div>

        <div class="spaces">
          <v-expansion-panels :accordion="accordion"
                              :readonly="readonly"
                              v-model="panel">
            <v-expansion-panel class="spaces-groups"
                               v-for="(item, key) in groups"
                               @click="toggleTab(item)"
                               :key="key">
              <v-expansion-panel-header class="spaces-group" ref="space_group">
                <v-row no-gutters>
                  <v-col class="spaces-group__title">
                    <span v-if="item.RoomCountListing">{{item.RoomCountListing}}-комнатные</span>
                    <span v-else>Другие</span>
                  </v-col>
                  <v-col class="spaces-group__label minarea">от {{item.MinAreaGeneral}} м2</v-col>
                  <v-col class="spaces-group__label price">от {{item.MinPriceInNationalCurrency | price }} ₴</v-col>
                  <v-col class="spaces-group__label price-full">от {{item.MinPriceInNationalCurrencyByM | price }}
                                                                грн/м2
                  </v-col>
                  <v-col class="spaces-group__areas">{{ item.ListingCount }} квартир</v-col>
                </v-row>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div v-if="items.length">
                  <v-data-table v-if="items.length" class="cc-table"
                                :loading="loading"
                                loading-text="Loading... Please wait"
                                :headers="headers"
                                :items-per-page="pageCount"
                                :custom-sort="sortSpaces"
                                :sort-by.sync="sortField"
                                :sort-desc.sync="sortDesc"
                                :header-props="headerProps"
                                hide-default-footer
                                :items="items">
                    <template v-slot:item.Photo="{ item }" class="table-photo">
                      <a :href="item.Photo.Original" data-fancybox class="table-photo__link">
                        <img :src="item.Photo.Big" class="table-photo__img" />
                      </a>
                    </template>

                    <template v-slot:item.Url="{ item }">
                      <a :href="item.Url" class="table-more">Подробнее</a>
                    </template>

                  </v-data-table>
                  <v-pagination v-model="page" class="cc-pagination" light
                                :total-visible="5"
                                :length="maxPage"></v-pagination>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-content>
    </v-app>
  </div>
</template>

<script>
  export default {
    name: "spacesByType",
    data() {
      return {
        accordion: true,
        readonly: true,
        panel: 0,
        loading: false,
        update: false,
        groups: [],
        activeGroup: {},
        page: 1,
        pageCount: 10,
        items: [],
        sortField: '',
        first_load: false,
        // sortDir: 'ASC',
        sortDesc: false,
        headerProps: {
          sortByText: "Сортировать по:"
        },
        headers: [
          {
            text: '',
            sortable: false,
            value: 'Photo',
          },
          {
            text: 'Площадь',
            value: 'AreaGeneral',
          },
          {
            text: 'Общая Цена',
            value: 'PriceInNationalCurrency',
          },
          {
            text: 'Цена за м2',
            value: 'PriceInNationalCurrencyByM',
          },
          {
            text: 'Тип',
            value: 'ListingActionName',
          },
          {
            text: '',
            value: 'Url',
            sortable: false,
            align: 'center',
            width: 100,
          },
        ],
      }
    },
    computed: {
      globalVars() {
        return this.$store.state.globalVars;
      },
      complexId() {
        return this.globalVars.complexId || this.getIdFromGallery();
      },
      maxPage() {
        return this.activeGroup.ListingCount ? Math.ceil(this.activeGroup.ListingCount / this.pageCount) : 0;
      },
      sortDir() {
        return this.sortDesc ? 'DESC' : 'ASC';
      }
    },
    watch: {
      // activeGroup: {
      //   deep: true,
      //   handler(val) {
      //     if (val && !this.update) {
      //       // console.log('activeGroup');
      //       this.changeActiveGroup();
      //     }
      //   }
      // },
      page(val) {
        if (val && !this.update) {
          // console.log('page');
          this.getSpaceListing();
        }
      },
      sortField(val) {
        if (val && !this.update) {
          // console.log('sortField');
          this.getSpaceListing();
        }
      },
      sortDir(val) {
        if (val && !this.update) {
          // console.log('sortDir');
          this.getSpaceListing();
        }
      },
    },
    mounted() {
      this.getGroups().then(() => {
        this.changeActiveGroup();
        this.first_load = true;
        this.handleGroupClick();
      })
    },
    methods: {
      toggleTab(item) {
        this.activeGroup = item;
        this.changeActiveGroup();
      },
      handleGroupClick() {
        let elements = this.$refs.space_group;
        elements.forEach(e => {
          e.$el.addEventListener('click', element => {
            console.log('element',element);
            console.log('handleGroupClick', element.target);
            element.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
          })
        })
      },
      sortSpaces(items, groupBy, groupDesc) {
        // Disable table sort
        return items;
      },
      compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const ax = a.RoomCountListing;
        const bx = b.RoomCountListing;

        let comparison = 0;
        if (typeof ax !== "object" && ax > bx) {
          comparison = 1;
        } else if (typeof ax !== "object" && ax < bx) {
          comparison = -1;
        } else {
          comparison = 3;
        }
        return comparison;
      },
      async getGroups() {
        let data = {
          apiMethod: 'ConstructionComplex/GetListingGroups',
          body: {
            complexId: this.complexId,
          },
        };

        return this.$store.dispatch('sendRequest', data).then((res) => {
          // console.log('getGroups', res);

          if (res.data) {
            this.groups = res.data.sort(this.compare);

            this.activeGroup = this.groups[0];

            //if (this.groups.length) {
            //  this.activeGroup = this.groups[0];
            //} else {
            //  //this.$el.parentElement.style.display = 'none';
            //}
          }

          // console.log('test');
        });
      } ,
      changeActiveGroup() {
        let active_tab = this.groups.findIndex(i => i === this.activeGroup);

        if (!this.first_load) {
          this.panel = active_tab;
        } else {
          if (active_tab !== this.panel) {
            this.panel = active_tab;
          } else {
            this.panel = false;
          }
        }

        this.items = [];
        this.page = 1;

        this.update = true;
        this.sortField = '';
        this.sortDesc = false;

        this.getSpaceListing();
      },
      getSpaceListing() {
        // console.log('getSpaceListing');
        let data = {
          apiMethod: 'ConstructionComplex/GetListings',
          body: {
            complexId: this.complexId,
            roomCount: this.activeGroup.RoomCountListing !== null ? this.activeGroup.RoomCountListing : -1,
            pageNumber: this.page,
            pageSize: this.pageCount,
            sortField: this.sortField,
            sortDirection: this.sortDir,
            // sortDirection: this.sortDesc ? 'DESC' : 'ASC',
          },
        };

        this.loading = true;
        this.update = true;
        this.$store.dispatch('sendRequest', data).then((res) => {
          // console.log('getSpaceListing', res);

          if (res.data) {
            this.items = res.data;
            this.loading = false;

            setTimeout(() => {
              this.update = false;
            }, 300);
          }
        });
      },
      getIdFromGallery() {
        let reg = /\/complex\/([0-9]+)\/bg\//;
        let link = document.querySelector('.block-info .complex-fancybox');

        if (link) {
          let href = link.getAttribute('href');
          let result = reg.exec(href);

          if (result.length > 1) {
            return parseInt(result[1]);
          }
        }
        return false;
      },
    },
  }
</script>

<style lang="scss">

  @import '../../../../../css_scss/scss/setup';

  .block-flats {
    &.block-single {
      padding: 0;
    }

    .main-title {
      font-family: $font;
      font-weight: bold;
      font-size: 36px;
      line-height: 34px;
      padding: 32px 24px 24px;

      @include tablet {
        font-size: 20px;
        line-height: 26px;
      }
    }

    .spaces-group {
      font-family: $font;

      &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $c-black;

        @include tablet {
          grid-area: 1/1/2/3;
          font-size: 13px;
          line-height: 13px;
          text-align: left;
        }
      }

      &__label {
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
        color: $c-dark-grey;

        &.minarea {
          @include tablet {
            display: flex;
            grid-area: 2/2/3/3;
            font-weight: 500;
            font-size: 9px;
            line-height: 9px;
            text-align: left;

            &:before {
              content: '(';
              display: flex;
            }

            &:after {
              content: ')';
              display: flex;
            }
          }
        }

        &.price {
          @include tablet {
            grid-area: 1/3/2/6;
            font-weight: 500;
            font-size: 13px;
            line-height: 13px;
            color: $c-black;
            text-align: right;
            margin-bottom: 6px;
          }
        }

        &.price-full {
          @include tablet {
            grid-area: 2/3/3/6;
            font-size: 9px;
            line-height: 9px;
            text-align: right;
          }
        }
      }

      &__areas {
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        text-align: right;
        color: $c-black;

        @include tablet {
          grid-area: 2/1/3/2;
          font-size: 9px;
          line-height: 9px;
          text-align: left;
        }
      }
    }

    .v-expansion {
      &-panels {
        border-radius: 0;
      }

      &-panel {
        &:before {
          box-shadow: none;
        }

        &:hover, &--active, &--active:hover {
          background-color: rgba($c-orange, .06);
        }

        &:focus, &--active:focus {
          background-color: transparent;
        }

        &-header {
          padding: 16px 10px 16px 24px;

          &:focus, &:hover {
            background-color: rgba($c-orange, .06);
          }

          .row {
            @include tablet {
              display: grid;
              grid-template-columns: 60px 100px repeat(3, 1fr);
              grid-template-rows: repeat(2, 1fr);
              align-items: center;
              margin-right: 12px;
            }
          }

          &--active {
            background-color: rgba($c-orange, .06);
            border-bottom: 1px solid rgba(#C0C5C9, .64);

            &:hover, &:focus {
              background-color: rgba($c-orange, .06);
            }
          }

          &__icon {
            margin-left: 4px;

            .v-icon {
              &:before {
                content: '';
                background: url("/Content/Images/caret-down.svg") transparent no-repeat center;
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
    }

    .v-data-table {
      font-family: $font;

      &__mobile-row {
        @include tablet {
          padding: 0;
        }
      }

      &__mobile-table-row:first-child {
        padding: 0;

        .v-data-table__mobile-row__cell {
          width: 100%;
          height: 100%;
        }
      }

      &-header {
        tr {
          vertical-align: middle;
        }

        th {
          position: relative;
          padding: 0;
          font-weight: 500;
          font-size: 9px;
          line-height: 1.2;
          color: rgba($c-black, .6);
          letter-spacing: 0.03em;
          white-space: nowrap;
          padding-right: 18px;
          text-transform: uppercase;

          &:first-child {
            width: 13.55vw;

            @include tablet {
              width: auto;
            }
          }

          span {
            white-space: normal;
          }
        }

        &__icon {
          position: absolute !important;
          top: auto;
          margin-top: -2px;

          &:before {
            content: '';
            background: url("/Content/Images/arrow-up-black.svg") transparent no-repeat center;
            width: 16px;
            height: 16px;

            @include mobile {
              display: none;
            }
          }
        }

        &-mobile {
          tr:last-child th {
            @include tablet {
              border-bottom: none;
            }
          }
        }
      }

      tbody {
        td {
          padding: 7px 0;
          border-bottom: 1px solid #EEF0F1;

          @include tablet {
            padding: 0;
            border: none;
          }

          &:nth-child(3), &:nth-child(4) {
            font-weight: bold;
          }

          .table-photo {

            &__link {
              max-height: 40px;

              @include tablet {
                height: 190px;
                max-height: 100%;
                max-width: 100px;
                padding: 0 10px;
                width: 100%;
                display: flex;
                border: none;
              }

              @include mobile {
                max-width: 100%;
                padding: 0;
                border: 1px solid #EEF0F1;
              }
            }

            &__img {
              width: auto;
              height: 100%;
              object-fit: contain;
              object-position: center;

              @include tablet {
                width: 100%;
              }
            }
          }

          .table-more {
            font-weight: 500;
            font-size: 13px;
            line-height: 13px;
            color: $c-grey;
            transition: $transition;

            &:hover {
              color: $c-orange;
              transition: $transition;
            }

            @include tablet {
              text-align: center;
              display: block;
              background-color: #FDF5E4;
              color: $c-orange;
              padding: 15px;
              margin: 16px auto 24px;
            }
          }
        }

        tr {
          &:hover {
            .table-more {
              color: $c-orange;
              transition: $transition;
            }
          }
        }
      }
    }

    .cc-pagination {
      display: flex;
      align-items: center;

      .v-pagination {
        &__navigation {
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          border: 1px solid #F1BE48;
          box-shadow: none;
          border-radius: 0;
          background-color: $c-orange;

          @include tablet {
            width: 24px;
            height: 24px;
          }

          .v-icon {
            &.mdi-chevron-left {
              &:before {
                content: '';
                background: url("/Content/Images/arrow-left-white.svg") $c-orange no-repeat center;
                width: 40px;
                height: 40px;

                @include tablet {
                  width: 8px;
                  height: 8px;
                }
              }

            }

            &.mdi-chevron-right {
              &:before {
                content: '';
                background: url("/Content/Images/arrow-right-white.svg") $c-orange no-repeat center;
                width: 40px;
                height: 40px;

                @include tablet {
                  width: 8px;
                  height: 8px;
                }
              }

            }
          }

          &--disabled {
            border-color: #E3E3E3;
            background-color: $c-white;

            .v-icon {
              &.mdi-chevron-left {
                &:before {
                  content: '';
                  background: url("/Content/Images/arrow-left-grey.svg") no-repeat center;
                  width: 40px;
                  height: 40px;

                  @include tablet {
                    width: 8px;
                    height: 8px;
                  }
                }

              }

              &.mdi-chevron-right {
                &:before {
                  content: '';
                  background: url("/Content/Images/arrow-right-grey.svg") no-repeat center;
                  width: 40px;
                  height: 40px;

                  @include tablet {
                    width: 8px;
                    height: 8px;
                  }
                }
              }
            }
          }
        }

        &__item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          color: $c-black;
          background: transparent;
          box-shadow: none;
          border-radius: 0;
          font-family: $font;
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;

          @include tablet {
            width: 24px;
            height: 24px;
          }

          &--active {
            &.primary {
              color: #F1BE48;
              background: #FDF5E4;
              box-shadow: none;
              border-radius: 0;
            }

          }
        }
      }
    }
  }

  .v-data-table__mobile-table-row {
    .v-data-table__mobile-row:first-child {
      .v-data-table__mobile-row__cell {
        width: 100%;
        height: 100%;
      }
    }
  }

  .theme--light.v-data-table.cc-table tbody tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: transparent;
  }

  .theme--light.v-data-table.cc-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row) {
    border-bottom: 1px solid #EEF0F1;
  }

  .theme--light.v-data-table.cc-table tbody tr:last-child td {
    border-bottom: none;
  }

  .v-data-table-header-mobile__wrapper .v-select .v-chip {
    padding: 0 10px;
    margin: 0 0 5px 0;
  }

  .v-data-table__mobile-row__cell {
    width: 100%;
  }

  .v-list-item__content {
    font-family: $font;
  }

  .block-flats .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    @include tablet {
      padding: 0;
      border: none;
      height: auto;
      font-size: 14px;
    }
  }

  .block-flats .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    @include tablet {
      padding: 0;
    }
  }
</style>

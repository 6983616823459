import { render, staticRenderFns } from "./popupFeedback.vue?vue&type=template&id=28745664&scoped=true&"
import script from "./popupFeedback.vue?vue&type=script&lang=js&"
export * from "./popupFeedback.vue?vue&type=script&lang=js&"
import style0 from "./popupFeedback.vue?vue&type=style&index=0&id=28745664&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28745664",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/skipin/NetBeansProjects/Address/Address.Web/Assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('28745664')) {
      api.createRecord('28745664', component.options)
    } else {
      api.reload('28745664', component.options)
    }
    module.hot.accept("./popupFeedback.vue?vue&type=template&id=28745664&scoped=true&", function () {
      api.rerender('28745664', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "sources/vue/components/popups/popupFeedback.vue"
export default component.exports
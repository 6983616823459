<template>
  <transition name="fade">
    <div class="vue-popup__overlay" @click.self="togglePopup" v-if="showPopup">
      <div class="vue-popup popup popup--feedback" @click.self="togglePopup">
        <button class="popup__close" @click="togglePopup"></button>

        <transition name="fade" mode="out-in">
<!--          <popup-login v-if="activePopup === 'login'"></popup-login>-->
          <popup-reminder v-if="activePopup === 'remind'"></popup-reminder>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
  // import PopupLogin from "./auth/popupLogin";
  import PopupReminder from "./auth/popupReminder";

  export default {
    name: "popupAuth",
    components: {
      PopupReminder,
      // PopupLogin,
    },
    data() {
      return {
        showPopup: false,
        activePopup: 'remind',
      }
    },
    mounted() {
      let btns = document.querySelectorAll('[data-auth-popup]');
      btns.forEach(button => {
        button.addEventListener('click', (e) => this.clickHandler(e));
      });

      document.addEventListener('showRemindPopup', () => {
        this.setActive('remind');
      });
    },
    methods: {
      clickHandler(e) {
        e.preventDefault();
        let btn = e.target,
          type = btn.dataset.authPopup;

        this.setActive(type);
      },
      setActive(type) {
        this.activePopup = type;

        this.togglePopup();
      },
      togglePopup() {
        this.showPopup = !this.showPopup;

        if (this.showPopup) {
          document.body.classList.add('overflow-hidden');
        } else {
          this.success = false;
          document.body.classList.remove('overflow-hidden');
        }
      },
    },
  }
</script>

<style scoped>

</style>

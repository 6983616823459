var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.showPopup
      ? _c(
          "div",
          {
            staticClass: "vue-popup__overlay",
            on: {
              click: function($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.togglePopup($event)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "vue-popup popup popup--feedback",
                on: {
                  click: function($event) {
                    if ($event.target !== $event.currentTarget) {
                      return null
                    }
                    return _vm.togglePopup($event)
                  }
                }
              },
              [
                _c("button", {
                  staticClass: "popup__close",
                  on: { click: _vm.togglePopup }
                }),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "fade", mode: "out-in" } },
                  [
                    _vm.activePopup === "remind"
                      ? _c("popup-reminder")
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
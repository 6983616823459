<template>
  <v-app>
    <transition name="fade">
      <div class="vue-popup__overlay" @click.self="togglePopup(true)" v-if="showPopup">
        <div class="vue-popup popup popup--request" @click.self="togglePopup(true)">
          <div class="popup__block">
            <button class="popup__close" @click="togglePopup"></button>
            <div class="popup__title block-heading">Отправить заявку</div>
            <form class="popup__form feedback">
              <v-select class="custom-select" return-object
                        v-model="models.category"
                        :items="requestOptions"
                        label="Категория сообщения"></v-select>

              <div class="feedback__input-wrap">
                <input type="text" placeholder="Имя" v-model="models.name" class="feedback__input" :class="{error: $v.models.name.$error}">

                <div class="form-field__error" v-if="$v.models.name.$error">{{ getErrorMessage($v.models.name) }}</div>
              </div>
              <div class="feedback__input-wrap">
                <input type="email" placeholder="Email" v-model="models.email" class="feedback__input" :class="{error: $v.models.name.$error}">

                <div class="form-field__error" v-if="$v.models.email.$error">{{ getErrorMessage($v.models.email) }}</div>
              </div>
              <div class="feedback__input-wrap">
                <input type="text"
                       placeholder="+38(000)000-00-00"
                       v-mask="'+38(###)###-##-##'"
                       class="feedback__input"
                       v-model="models.phone" :class="{error: $v.models.phone.$error}">

                <div class="form-field__error" v-if="$v.models.phone.$error">{{ getErrorMessage($v.models.phone) }}</div>
              </div>
              <div class="feedback__text">
                <textarea name="message" :class="{error: $v.models.message.$error}"
                          class="feedback__textarea"
                          id="feedback-message"
                          v-model="models.message"
                          :placeholder="description">
                </textarea>

                <div class="form-field__error"
                     v-if="$v.models.message.$error || !$v.models.message.withoutUrls">
                  {{ getErrorMessage($v.models.message) }}
                </div>
              </div>
              <div class="feedback__btn">
                <button type="button" @click="submit" class="feedback__submit">Отправить</button>
              </div>

              <div>
                <div class="form-field__success" v-if="success">{{ successMessage }}</div>
                <div class="form-field__error" v-if="error">{{ errorMessage }}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
  import { required, minLength, email, helpers } from 'vuelidate/lib/validators';

  const urlRegex = /(https?|ftp|file):\/\//g;
  const withoutUrls = (value) => !helpers.req(value) || !urlRegex.test(value);

  export default {
    name: "popupRequest",
    data() {
      return {
        showPopup: false,
        success: false,
        error: false,
        successMessage: '',
        errorMessage: 'При запросе или во время возвращения данных произошел сбой',
        description: 'С помощью данной формы Вы можете оставить заявку ' +
          'на просмотр квартиры или задать Ваш вопрос.',
        requestOptions: [
          {
            text: 'Договориться о просмотре',
            value: 'review',
          },
          {
            text: 'Задать вопрос',
            value: 'question',
          }
        ],
        models: {
          category: {
            text: 'Договориться о просмотре',
            value: 'review',
          },
          name: '',
          email: '',
          phone: '',
          message: '',
        },
        apiUrl: "/WebServices/Notification.asmx/BookInComplexSS",
      }
    },
    validations: {
      models: {
        name: {
          required,
          minLength: minLength(4),
        },
        email: {
          required,
          email,
        },
        message: {
          required,
          withoutUrls,
        },
        phone: {
          required,
          minLength: minLength(11),
        }
      },
    },
    mounted() {
      let btns = document.querySelectorAll('[data-popup=request]');
      btns.forEach(button => {
        button.addEventListener('click', (e) => this.clickHandler(e));
      });
    },
    computed: {
      globalVars() {
        return this.$store.state.globalVars;
      },
      complexId() {
        return this.globalVars.complexId || this.getIdFromGallery();
      },
      complexName() {
        let title = document.querySelector('.complex-single .block-info__text .title');

        return this.globalVars.complexName || title.textContent;
      },
      developerId() {
        return this.globalVars.developerId || this.getDeveloperIdFromLogo();
      },
    },
    methods: {
      clickHandler(e) {
        e.preventDefault();

        this.togglePopup();
      },
      togglePopup(hide = false) {
        this.showPopup = hide ? !hide : !this.showPopup;

        if (this.showPopup) {
          document.body.classList.add('overflow-hidden');
        } else {
          document.body.classList.remove('overflow-hidden');
        }
      },
      submit() {
        console.log('submit!');

        this.$v.$touch();

        if (this.$v.$invalid) {
          this.error = true;
        } else {
          let body = Object.assign(this.models, {
            complexId: this.complexId,
            developerId: this.developerId,
            complexName: this.complexName,
          });

          body.category = body.category.text;

          let data = {
            url: this.apiUrl,
            method: 'post',
            type: 'json',
            body: body,
          };

          this.error = false;
          this.$store.dispatch('sendRequest', data).then((res) => {
            console.log('Form', res);

            if (res.data.d.IsSuccess) {
              this.success = true;
              this.successMessage = res.data.d.OutMessage;

              setTimeout(() => {
                this.resetComponent();
              }, 400);
            } else {
              this.error = true;
            }
          });
        }
      },
      resetComponent() {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();
      },
      getIdFromGallery() {
        let reg = /\/complex\/([0-9]+)\/bg\//;
        let link = document.querySelector('.block-info .complex-fancybox');

        if (link) {
          let href = link.getAttribute('href');
          let result = reg.exec(href);

          if (result.length > 1) {
            return parseInt(result[1]);
          }
        }
        return false;
      },
      getDeveloperIdFromLogo() {
        let reg = /\/avatar\/Avatara_([0-9]+)_[0-9]+\.[a-z]{3}/;
        let logo = document.querySelector('.developer-logo');

        console.log(logo);

        if (logo) {
          let href = logo.getAttribute('src');
          let result = reg.exec(href);

          console.log(result);

          if (result && result.length > 1) {
            return parseInt(result[1]);
          }
        }
        return false;
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>

<style lang="scss" scoped>
  @import '../../../../../css_scss/scss/setup';

  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s ease;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .overflow-hidden {
    overflow: hidden;
    height: 100vh;
  }

  .vue-popup {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transition: all .5s ease-in-out;
    min-width: 300px;
    min-height: 300px;
    padding: 24px;
    position: relative;
    &__overlay

  {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
  }
  }
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.groups && _vm.groups.length
    ? _c("div", { staticClass: "block-single block-stages" }, [
        _c("div", { staticClass: "title h3" }, [
          _vm._v("Динамика строительства")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "construction-progress" }, [
          _c(
            "div",
            { staticClass: "construction-progress__tabs main-tabs" },
            _vm._l(_vm.groups, function(group) {
              return _c(
                "div",
                {
                  staticClass: "construction-progress__tab tab-main",
                  class: { active: group.Id === _vm.activeGroup.Id },
                  on: {
                    click: function($event) {
                      _vm.activeGroup = group
                    }
                  }
                },
                [_c("span", [_vm._v(_vm._s(group.Name) + " год")])]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "construction-progress__tabs children-tabs" },
            _vm._l(_vm.activeGroup.Photos, function(child) {
              return _c(
                "div",
                {
                  staticClass: "construction-progress__tab tab-child",
                  class: { active: child.Id === _vm.activeChild.Id },
                  on: {
                    click: function($event) {
                      _vm.activeChild = child
                    }
                  }
                },
                [_c("span", [_vm._v(_vm._s(child.Name))])]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "photoContainer",
              staticClass: "construction-progress__photos"
            },
            [
              _vm._l(_vm.photos, function(item) {
                return _c(
                  "div",
                  { key: item.Id, staticClass: "construction-progress__photo" },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: item.Photo,
                          "data-fancybox": "construction-progress"
                        }
                      },
                      [_c("img", { attrs: { src: item.Photo } })]
                    )
                  ]
                )
              }),
              _vm._v(" "),
              _vm.activeChild.More
                ? _c("div", { staticClass: "construction-progress__photo" }, [
                    _c(
                      "div",
                      {
                        staticClass: "load_more_photo",
                        on: { click: _vm.loadMore }
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("camera-icon"),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  "Еще " + _vm.activeChild.More + " фото"
                                ) +
                                "\n          "
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
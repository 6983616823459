<template>
  <v-app ref="main">
    <v-content class="content">
      <div class="address-container">
        <div class="buildings-filters__container">
          <div class="buildings-filters__tabs">
            <div class="buildings-filters__tab"
                 :class="{ active: checkActive(tab) }"
                 v-for="tab in tabs">
              <a :href="tab.url">{{ tab.title }}</a>
            </div>
          </div>

          <!--          <form action="/developers/kompleksy/" method="post" ref="form" v-on:submit.prevent="testSubmit">-->
          <form action="/developers/kompleksy/" method="post" ref="form">
            <!--          <form action="https://address.ua/developers/kompleksy/" method="post" ref="form">-->

            <input id="formId" name="formId" type="hidden" value="ComplexesViewModel">
            <input id="IsAction" name="IsAction" type="hidden" value="false">

            <input type="hidden" name="region" :value="selectedIds.regionId">
            <input type="hidden" name="districtIds" :value="selectedIds.districtIds.join(',')">
            <input type="hidden" name="subdistrictIds" :value="selectedIds.subDistrictIds.join(',')">

            <input type="hidden" name="ComplexTypeId" :value="models.complexType.id">

            <div class="buildings-filters">
              <div class="buildings-filters__field rel">
                <v-tooltip bottom
                           max-width="340"
                           content-class="select-tooltip">
                  <template v-slot:activator="{ on }">
                    <div class="pointer" data-vue="modal_location">
                      <v-text-field name="" readonly class="custom-input pointer" :key="selectedIds.regionId"
                                    label="Область, город, район" v-model="models.city"></v-text-field>
                    </div>
                  </template>
                  <!--<span>{{ tooltip }}</span>-->
                </v-tooltip>
              </div>

              <div class="buildings-filters__field">
                <building-search name="ComplexName2" :complex-name="models.complexName"></building-search>
              </div>

              <div class="buildings-filters__field">
                <v-select name="ConstructionStageId" class="custom-select"
                          label="Срок сдачи"
                          :items="stages" v-model="models.stage"></v-select>
              </div>
              <div class="buildings-filters__field wrapped">
                <div class="buildings-filters__pricing">
                  <v-menu :close-on-click="menuOptions.closeOnClick"
                          :close-on-content-click="menuOptions.closeOnContentClick"
                          :offset-y="menuOptions.offsetY">

                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="custom-input pointer"
                                    :hint="models.priceType.text"
                                    label="Цена, от"
                                    persistent-hint
                                    name="PricePerMeterFrom"
                                    v-bind="attrs"
                                    v-on="on"
                                    v-model="models.price.from"></v-text-field>
                    </template>

                    <v-list v-if="prices.from.length">
                      <v-list-item v-for="(item, index) in prices.from"
                                   :key="index"
                                   @click="models.price.from = parseInt(item.Price)">
                        <v-list-item-title>от {{ parseInt(item.Price) }} {{ item.CurrencySign }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <div class="price-label">За м2</div>
                </div>

                <div class="buildings-filters__pricing">
                  <v-menu :close-on-click="menuOptions.closeOnClick"
                          :close-on-content-click="menuOptions.closeOnContentClick"
                          :offset-y="menuOptions.offsetY">

                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field class="custom-input pointer"
                                    :hint="models.priceType.text"
                                    label="Цена, до"
                                    persistent-hint
                                    name="PricePerMeterTo"
                                    v-bind="attrs"
                                    v-on="on"
                                    v-model="models.price.to"></v-text-field>
                    </template>

                    <v-list v-if="prices.to.length">
                      <v-list-item v-for="(item, index) in prices.to"
                                   :key="index"
                                   @click="models.price.to = parseInt(item.Price)">
                        <v-list-item-title>до {{ parseInt(item.Price) }} {{ item.CurrencySign }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <div class="price-label">За м2</div>
                </div>

                <div class="buildings-filters__currency">
                  <v-select disabled class="custom-select"
                            :items="currencies" v-model="models.currency"></v-select>
                </div>
              </div>
              <div class="form_button buildings-filters__submit">
                <button type="submit" disabled class="btn btn-main">Найти</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <popup-location></popup-location>
    </v-content>
  </v-app>
</template>

<script>
  import BuildingSearch from "../../components/autocompletes/buildingSearch";
  import PopupLocation from "../../components/popups/popupLocation";
  // import queryString from 'querystring';
  import qs from 'qs';

  export default {
    name: "buildingsFilter",
    components: {PopupLocation, BuildingSearch},
    data() {
      return {
        tabs: [
          {
            title: 'Многоквартирные дома',
            url: '/novostroyki/',
            id: 1,
          },
          {
            title: 'Коттеджные городки',
            url: '/kottedzhnyy-gorodok/',
            id: 2,
          },
        ],
        models: {
          city: '',
          type: '',
          search: false,
          stage: null,
          currency: {
            value: 3,
            text: '₴',
          },
          priceType: {
            value: 2,
            text: 'За кв.м.',
          },
          complexType: {},
          complexName: false,
          price: {
            from: '',
            to: '',
          },
        },
        example: 'Например, Дарницкий, Срибнокильская 12',
        stages: [],
        priceTypes: [
          {
            value: 1,
            text: 'За весь объект',
          },
          {
            value: 2,
            text: 'За кв.м.',
          },
        ],
        currencies: [
          {
            value: 3,
            text: '₴',
          },
        ],
        prices: {
          from: [],
          to: [],
        },
        menuOptions: {
          show: false,
          closeOnClick: true,
          closeOnContentClick: true,
          offsetY: true,
        },
        curPath: '',
      }
    },
    mounted() {
      document.addEventListener('click', e => this.clickEvent(e));

      this.getStages();
      this.getPrices();

      this.parseQuery();
    },
    computed: {
      currentPath: {
        get() {
          return window.location.pathname;
        },
        set(val) {
          this.curPath = val;
        }
      },
      selectedIds: {
        get() {
          return this.$store.state.selected;
        },
        set(value) {
          this.$store.commit('setter', {selected: value});
        }
      },
      tooltip() {
        let objects = [];
        let districtIds = this.selectedIds.districtIds;
        let subDistrictIds = this.selectedIds.subDistrictIds;

        if (districtIds.length) {
          objects = objects.concat(
            this.objects.cityDistricts.filter(i => districtIds.includes(i.Id)).map(i => i.Name),
            this.objects.districts.filter(i => districtIds.includes(i.Id)).map(i => i.Name),
          );
        }
        if (subDistrictIds.length) {
          objects = objects.concat(
            this.objects.cities.filter(i => subDistrictIds.includes(i.Id)).map(i => i.Name),
            this.objects.SubDistricts.filter(i => subDistrictIds.includes(i.Id)).map(i => i.Name),
          );
        }

        let tooltip = objects.join(', ');
        if (tooltip.length) {
          this.models.city = tooltip;
          return tooltip;
        } else {
          this.models.city = this.example;
          return this.example;
        }
      },
      objects() {
        return this.$store.state.locationObjects;
      },
    },
    methods: {
      getStages() {
        let data = {
          apiMethod: 'constructionStage/getList',
          body: {
            typeId: this.models.complexType.id,
          },
        };

        this.$store.dispatch('sendRequest', data).then((res) => {
          // console.log('getTypes', res);
          this.stages = res.data.map(i => {
            return {
              text: i.Name,
              value: i.Id,
            }
          });

          if (this.stages.length && !this.stages) {
            this.models.stage = this.stages[0].value;
          }
        });
      },
      getPrices() {
        let data = {
          apiMethod: 'priceSearchItem/getList',
          body: {
            currencyId: this.models.currency.value,
            priceTypeId: this.models.priceType.value,
            complexTypeId: this.models.complexType.id,
          },
        };

        this.$store.dispatch('sendRequest', data).then((res) => {
          // console.log('getPrices', res);

          if (res.data && res.data.length) {
            this.prices.from = res.data;
            this.prices.to = res.data;

            if (!this.models.price.from) {
              // this.models.price.from = parseInt(res.data[0].Price);
              this.models.price.from = '';
            }

            if (!this.models.price.to) {
              // this.models.price.to = parseInt(res.data[res.data.length - 1].Price);
              this.models.price.to = '';
            }
          }
        });
      },
      checkActive(tab) {
        if (this.currentPath.includes(tab.url)) {
          this.$store.commit('setter', {
            complexType: tab.id,
          });
          this.models.complexType = tab;
          return true;
        } else {
          return false;
        }

      },
      clickEvent(event) {
        let path = event.path || (event.composedPath && event.composedPath());

        if (!path.includes(this.$el)) {
          let app = document.querySelector('[data-app]') || document.body; // This is only for unit tests

          app.click();
        }
      },
      showPopup() {
        this.$store.commit('setter', {
          showPopup: true,
        });
      },
      parseQuery() {
        let args = qs.parse(window.location.search.slice(1));

        Object.entries(args).forEach(([key, value]) => {
          switch (key) {
            case 'constructionstageid':
              this.models.stage = parseInt(args[key]);
              break;
            case 'pricepermeterfrom':
              this.models.price.from = parseInt(args[key]);
              break;
            case 'pricepermeterto':
              this.models.price.to = parseInt(args[key]);
              break;
            case 'complexname':
              this.models.complexName = args[key];
              break;
          }
        });
      },
      testSubmit() {
        let data = Object.fromEntries(new FormData(this.$refs.form).entries());

        console.log(data);
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../../../../css_scss/scss/setup';

  .buildings-filters {
    display: flex;

    @include tablet {
      flex-direction: column;
    }

    &__container {
      background-color: $c-white;
      padding: 40px 24px;
      margin-bottom: 40px;

      @include tablet {
        margin-top: 40px;
        margin-bottom: 0;
        padding: 24px 16px 16px;
      }
    }

    &__tabs {
      display: flex;
      margin-bottom: 24px;
    }

    &__tab {
      position: relative;
      padding: 10px 0;
      font-family: $font;
      font-weight: 500;
      font-size: 13px;

      &:not(:last-child) {
        margin-right: 28px;
      }

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: transparent;
        transition: all 0.4s;
      }

      &.active, &:hover {
        &:after {
          background: #F1BE48;
        }
      }
    }

    &__field {
      @include tablet {
        margin-right: 0;
      }

      &:not(:last-child) {
        margin-right: 8px;
        @include tablet {
          margin-right: 0;
          margin-bottom: 8px;
        }
      }
      &:first-child {
        width: 25%;
        @include tablet {
          width: 100%;
        }
      }
      &:nth-child(2) {
        width: 27%;
        @include tablet {
          width: 100%;
        }
      }
      &:nth-child(3) {
        width: 12%;
        @include tablet {
          width: 100%;
        }
      }
      &.wrapped {
        display: flex;
        align-items: center;
        width: 20%;
        @include tablet {
          width: 100%;
        }
      }

      input {
        color: $c-black;
        @include placeholder {
          color: $c-black;
        }
      }
    }

    &__pricing, &__currency {

    }

    &__currency {
      width: 40px;
      @include tablet {
        margin-left: 8px;
      }
    }

    &__submit {
      width: 15%;
      @include tablet {
        width: 100%;
        margin-top: 8px;
      }

      button {
        width: 100%;
        height: 100%;
        min-height: 40px;
        opacity: 1;
      }
    }
  }

  .select-tooltip {
    margin-top: -5px;
  }
</style>

<style>
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }
</style>

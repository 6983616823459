<template>
  <v-app>
    <transition name="fade">
      <div class="vue-popup__overlay" @click.self="togglePopup(true)" v-if="showPopup">
        <div class="vue-popup popup popup--feedback" @click.self="togglePopup(true)">
          <div class="popup__block">
            <button class="popup__close" @click="togglePopup"></button>
            <div class="popup__title block-heading">Обратная связь</div>
            <form class="popup__form feedback">
              <v-select class="custom-select" v-model="models.feedbackType" :items="feedbackOptions"
                        label="Тема сообщения"></v-select>
              <div class="feedback__input-wrap">
                <input type="text" placeholder="Имя"
                       class="feedback__input"
                       :class="{error: $v.models.name.$error}"
                       v-model="models.name">

                <div class="form-field__error" v-if="$v.models.name.$error">{{ getErrorMessage($v.models.name) }}</div>
              </div>
              <div class="feedback__input-wrap">
                <input type="email" placeholder="Email"
                       class="feedback__input"
                       :class="{error: $v.models.email.$error}"
                       v-model="models.email">

                <div class="form-field__error" v-if="$v.models.email.$error">{{ getErrorMessage($v.models.email) }}</div>
              </div>
              <div class="feedback__text">
                <textarea name="message"
                          class="feedback__textarea"
                          :class="{error: $v.models.message.$error || !$v.models.message.withoutUrls}"
                          id="feedback-message"
                          v-model="models.message"
                          :placeholder="description">
              </textarea>

                <div class="form-field__error"
                     v-if="$v.models.message.$error || !$v.models.message.withoutUrls">
                  {{ getErrorMessage($v.models.message) }}
                </div>
              </div>
              <div class="feedback__btn">
                <button type="button" @click="submit" class="feedback__submit">Отправить</button>
              </div>
              <div>
                <div class="form-field__success" v-if="success">{{ successMessage }}</div>
                <div class="form-field__error" v-if="error">{{ errorMessage }}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
  import { required, minLength, email, helpers } from 'vuelidate/lib/validators';

  const urlRegex = /(https?|ftp|file):\/\//g;
  const withoutUrls = (value) => !helpers.req(value) || !urlRegex.test(value);


  export default {
    name: "popupFeedback",
    data() {
      return {
        showPopup: false,
        success: false,
        error: false,
        successMessage: '',
        errorMessage: 'При запросе или во время возвращения данных произошел сбой',
        description: 'С помощью данной формы Вы можете сообщить об ошибке в работе портала, ' +
          'задать вопрос или оставить свое мнение о сервисах портала.',
        feedbackOptions: [
          {
            text: 'Сообщить об ошибке',
            value: 'Error',
          }, {
            text: 'Предложение по улучшению',
            value: 'Improvement',
          }, {
            text: 'Другое',
            value: 'Other',
          }
        ],
        models: {
          feedbackType: 'Error',
          name: '',
          email: '',
          message: '',
        },
        apiUrl: "/WebServices/Notification.asmx/SendFeedbackSS",
      }
    },
    validations: {
      models: {
        name: {
          required,
          minLength: minLength(4)
        },
        email: {
          required,
          email,
        },
        message: {
          required,
          withoutUrls,
        }
      },
    },
    mounted() {
      let btns = document.querySelectorAll('[data-popup=feedback]');
      btns.forEach(button => {
        button.addEventListener('click', (e) => this.clickHandler(e));
      });
    },
    methods: {
      clickHandler(e) {
        e.preventDefault();

        this.togglePopup();
      },
      togglePopup(hide = false) {
        this.showPopup = hide ? !hide : !this.showPopup;

        if (this.showPopup) {
          document.body.classList.add('overflow-hidden');
        } else {
          this.success = false;
          document.body.classList.remove('overflow-hidden');
        }
      },
      submit() {
        console.log('submit!');

        this.$v.$touch();

        if (this.$v.$invalid) {
          this.error = true;
        } else {
          let data = {
            url: this.apiUrl,
            method: 'post',
            type: 'json',
            body: Object.assign(this.models, {
              listingId: 0,
            }),
          };

          this.error = false;
          this.$store.dispatch('sendRequest', data).then((res) => {
            console.log('Form', res);

            if (res.data.d.IsSuccess) {
              this.success = true;
              this.successMessage = res.data.d.OutMessage;

              setTimeout(() => {
                this.resetComponent();
              }, 400);
            } else {
              this.error = true;
            }
          });
        }
      },
      resetComponent() {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../../../../css_scss/scss/setup';

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease-in-out;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .overflow-hidden {
    overflow: hidden;
    height: 100vh;
  }

  .vue-popup {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transition: all .5s ease-in-out;
    min-width: 300px;
    min-height: 300px;
    padding: 24px;
    position: relative;
    &__overlay

  {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;
  }
  }
</style>

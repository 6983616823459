import Vue from 'vue';
import './store/mixin';
// import './store/translates';
import store from './store/store';
// import router from './store/router';
import vuetify from "./store/vuetify";

import VueMask from 'v-mask';
Vue.use(VueMask);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

// Only for debug
import { DataDump } from 'vue-dump';
Vue.component('dump', DataDump);

// Import components
import popupLocation from './components/popups/popupLocation';
import homeSearch from './pages/Home/homeSearch';
import popupRequest from "./components/popups/popupRequest";
import popupFeedback from "./components/popups/popupFeedback";
import popupAuth from "./components/popups/popupAuth";
import buildingsFilter from "./pages/Buildings/buildingsFilter";
import constructionProgress from "./pages/Building/constructionProgress";
import priceDynamics from "./pages/Building/priceDynamics";
import spacesByType from "./pages/Building/spacesByType";

import popupServices from "./components/popups/popupServices";


console.log('Vue init');


store.dispatch('load').then(()=>{
  const popupContainer = document.querySelector('[data-vue=app]');
  if (popupContainer) {
    new Vue({
      el: popupContainer,
      render: h => h(popupLocation),
      store,
        vuetify,
    });
  }



const modalLocation = document.querySelector('[data-vue=modal_location]');
  if (modalLocation) {
    let locationContainer = document.createElement('div');
    modalLocation.before(locationContainer);

    new Vue({
      el: locationContainer,
      render: h => h(popupLocation),
      store,
      vuetify,
    });
  }


  const searchContainer = document.querySelector('.hero__search');
  if (searchContainer) {
    let container = document.createElement('div');
    searchContainer.innerHTML = '';
    searchContainer.appendChild(container);

    new Vue({
      el: container,
      render: h => h(homeSearch),
      store,
      vuetify,
    });
  }

  let popupCont = document.createElement('div');
  if (document.body) {
    document.body.append(popupCont);
    new Vue({
      el: popupCont,
      render: h => h(popupRequest),
      store,
        vuetify,
    });
  }

  let feedbackCont = document.createElement('div');
  if (document.body) {
    document.body.append(feedbackCont);
    new Vue({
      el: feedbackCont,
      render: h => h(popupFeedback),
      store,
        vuetify,
    });
  }

  let authCont = document.createElement('div');
  if (document.body) {
    document.body.append(authCont);
    new Vue({
      el: authCont,
      render: h => h(popupAuth),
      store,
      vuetify,
    });
  }

  let list = document.querySelector('.complexes-list');
  // let list = document.querySelector('.address-container');
  if (list) {
    // let filterContainer = document.querySelector('.header_top');
    let filterContainer = document.createElement('div');
    list.parentElement.before(filterContainer);

    new Vue({
      el: filterContainer,
      render: h => h(buildingsFilter),
      store,
      vuetify,
    });
  }

  let spacesContainer = document.querySelector('[data-vue=spaces-by-type]');
  if (spacesContainer) {
    new Vue({
      el: spacesContainer,
      render: h => h(spacesByType),
      store,
      vuetify,
    });
  }


  let dynamicContainer = document.querySelector('[data-vue=price-dynamics]');
  if (dynamicContainer) {
    new Vue({
      el: dynamicContainer,
      render: h => h(priceDynamics),
      store,
      // vuetify,
    });
  }

  let progressContainer = document.querySelector('[data-vue=building-progress]');
  if (progressContainer) {
    new Vue({
      el: progressContainer,
      render: h => h(constructionProgress),
      store,
      vuetify,
    });
  }

  let services = document.querySelector('.services__packages');
  if (services) {
    let servicesContainer = document.createElement('div');
    document.body.append(servicesContainer);

    new Vue({
      el: servicesContainer,
      render: h => h(popupServices),
      store,
      // vuetify,
    });
  }
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "popup popup--filters",
      class: { "popup--active": _vm.popupVisible },
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.hidePopup()
        }
      }
    },
    [
      _c("div", { staticClass: "popup__block popup__wrapper" }, [
        _c("div", {
          staticClass: "popup__close",
          on: {
            click: function($event) {
              return _vm.hidePopup()
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "popup__left" }, [
          _c("div", { staticClass: "title" }, [_vm._v("Область")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "popup--filters__list" },
            _vm._l(_vm.objects.regions, function(item) {
              return _c(
                "div",
                {
                  key: item.Id,
                  staticClass: "item",
                  class: { active: _vm.activeRegion === item },
                  on: {
                    click: function($event) {
                      return _vm.toggleRegion(item)
                    }
                  }
                },
                [_c("span", [_vm._v(_vm._s(item.Name))])]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "popup__right", class: { active: _vm.isExpanded } },
          [
            _c(
              "div",
              {
                staticClass: "back",
                on: {
                  click: function($event) {
                    return _vm.toggleMobileRegion()
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: "/Content/Images/arrow-left-grey2.svg",
                    alt: ""
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v("К списку областей")])
              ]
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "tabs" },
              _vm._l(_vm.tabs[_vm.tabsType], function(tab) {
                return _c(
                  "li",
                  {
                    key: tab.id,
                    staticClass: "tab",
                    class: { active: _vm.currentTab === tab.id },
                    on: {
                      click: function($event) {
                        _vm.currentTab = tab.id
                      }
                    }
                  },
                  [_c("span", [_vm._v(_vm._s(tab.name))])]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "popup__content" },
              [
                _c(
                  "transition",
                  { attrs: { name: "fade", mode: "out-in", tag: "div" } },
                  [
                    _vm.currentTab === "area"
                      ? _c("div", [
                          _c(
                            "ul",
                            { staticClass: "list" },
                            _vm._l(_vm.objects.cityDistricts, function(item) {
                              return _c(
                                "li",
                                {
                                  staticClass: "list-item list-item--parent",
                                  class: [
                                    { active: _vm.activeDropdown === item.Id },
                                    { activeChild: _vm.isCityDistrictExpanded }
                                  ]
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "list-item--parent__content"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.models.cityDistricts,
                                            expression: "models.cityDistricts"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "cityDistrict-" + item.Id
                                        },
                                        domProps: {
                                          value: item.Id,
                                          checked: Array.isArray(
                                            _vm.models.cityDistricts
                                          )
                                            ? _vm._i(
                                                _vm.models.cityDistricts,
                                                item.Id
                                              ) > -1
                                            : _vm.models.cityDistricts
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$a =
                                                  _vm.models.cityDistricts,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = item.Id,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.models,
                                                      "cityDistricts",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.models,
                                                      "cityDistricts",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.models,
                                                  "cityDistricts",
                                                  $$c
                                                )
                                              }
                                            },
                                            function($event) {
                                              return _vm.changeMain(
                                                item,
                                                $event
                                              )
                                            }
                                          ]
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "list-item--label",
                                          attrs: {
                                            for: "cityDistrict-" + item.Id
                                          }
                                        },
                                        [_vm._v(_vm._s(item.Name))]
                                      ),
                                      _vm._v(" "),
                                      item.SubDistricts
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "chosen" },
                                              [
                                                _vm._v("Выбрано "),
                                                _c("span", [
                                                  _vm._v(_vm._s(item.selected))
                                                ]),
                                                _vm._v(" микрорайонов")
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "popup__dropdown",
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleDropdown(
                                                    item
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  item.SubDistricts
                                    ? _c(
                                        "ul",
                                        {
                                          class: {
                                            active: _vm.isCityDistrictExpanded
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "back",
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleMobileCityDistrict()
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    "/Content/Images/arrow-left-grey2.svg",
                                                  alt: ""
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v("К списку районов")
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(item.SubDistricts, function(
                                            itemChild
                                          ) {
                                            return _c("li", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.models.SubDistricts,
                                                    expression:
                                                      "models.SubDistricts"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id:
                                                    "SubDistrict-" +
                                                    itemChild.Id
                                                },
                                                domProps: {
                                                  value: itemChild.Id,
                                                  checked: Array.isArray(
                                                    _vm.models.SubDistricts
                                                  )
                                                    ? _vm._i(
                                                        _vm.models.SubDistricts,
                                                        itemChild.Id
                                                      ) > -1
                                                    : _vm.models.SubDistricts
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.models
                                                            .SubDistricts,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = itemChild.Id,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.models,
                                                              "SubDistricts",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.models,
                                                              "SubDistricts",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.models,
                                                          "SubDistricts",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.changeSubDistrict(
                                                        itemChild,
                                                        item,
                                                        $event
                                                      )
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for:
                                                      "SubDistrict-" +
                                                      itemChild.Id
                                                  }
                                                },
                                                [_vm._v(_vm._s(itemChild.Name))]
                                              )
                                            ])
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm.currentTab === "metro"
                      ? _c("div", [
                          _c("div", { staticClass: "metro-wrapper" }, [
                            _c("ul", { staticClass: "list list-metro" }, [
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "list-item list-item--parent list-item--metro"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "list-item--parent__content"
                                    },
                                    [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m1" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "list-item--label",
                                          attrs: { for: "m1" }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/Content/Images/metro-green.svg",
                                              alt: ""
                                            }
                                          }),
                                          _vm._v(
                                            "Сырецко-Печерская\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "chosen" }, [
                                        _vm._v("Выбрано 7 станций")
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "dropdown" })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("ul", [
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m11" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m11" } }, [
                                        _vm._v("Голосеево")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m12" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m12" } }, [
                                        _vm._v("Демеевка ")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m13" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m13" } }, [
                                        _vm._v("Корчеватое")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m14" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m14" } }, [
                                        _vm._v("с. Чапаевка")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m15" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m15" } }, [
                                        _vm._v("Саперная Слободка")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m16" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m16" } }, [
                                        _vm._v("Теремки-1 ")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m17" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m17" } }, [
                                        _vm._v("Теремки-2")
                                      ])
                                    ])
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "list-item list-item--parent list-item--metro"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "list-item--parent__content"
                                    },
                                    [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m2" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "list-item--label",
                                          attrs: { for: "m2" }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/Content/Images/metro-blue.svg",
                                              alt: ""
                                            }
                                          }),
                                          _vm._v(
                                            "Куренёвско-Красноармейская\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "chosen" }, [
                                        _vm._v("Выбрано 7 станций")
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "dropdown" })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("ul", [
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m21" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m21" } }, [
                                        _vm._v("Голосеевский")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m22" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m22" } }, [
                                        _vm._v("Голосеевский")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m23" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m23" } }, [
                                        _vm._v("Голосеевский")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m24" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m24" } }, [
                                        _vm._v("Голосеевский")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m25" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m25" } }, [
                                        _vm._v("Голосеевский")
                                      ])
                                    ])
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  staticClass:
                                    "list-item list-item--parent list-item--metro"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "list-item--parent__content"
                                    },
                                    [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m3" }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "list-item--label",
                                          attrs: { for: "m3" }
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                "/Content/Images/metro-red.svg",
                                              alt: ""
                                            }
                                          }),
                                          _vm._v(
                                            "Святошинско-Броварская\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "chosen" }, [
                                        _vm._v("Выбрано 7 станций")
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "dropdown" })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("ul", [
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m31" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m31" } }, [
                                        _vm._v("Голосеевский")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m32" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m32" } }, [
                                        _vm._v("Голосеевский")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m33" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m33" } }, [
                                        _vm._v("Голосеевский")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m34" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m34" } }, [
                                        _vm._v("Голосеевский")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _c("input", {
                                        attrs: { type: "checkbox", id: "m35" }
                                      }),
                                      _vm._v(" "),
                                      _c("label", { attrs: { for: "m35" } }, [
                                        _vm._v("Голосеевский")
                                      ])
                                    ])
                                  ])
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "magnifier" }, [
                              _c("div", { staticClass: "large" }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "small",
                                attrs: {
                                  src: "/Content/Images/metro-map.png",
                                  width: "360"
                                }
                              })
                            ])
                          ])
                        ])
                      : _vm.currentTab === "cities"
                      ? _c("div", [
                          _c(
                            "ul",
                            { staticClass: "list" },
                            _vm._l(_vm.objects.cities, function(item) {
                              return _c(
                                "li",
                                {
                                  staticClass: "list-item list-item--parent",
                                  class: {
                                    active: _vm.activeDropdown === item.Id,
                                    activeChild: _vm.isDistrictExpanded
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "list-item--parent__content"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.models.cities,
                                            expression: "models.cities"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "city-" + item.Id
                                        },
                                        domProps: {
                                          value: item.Id,
                                          checked: Array.isArray(
                                            _vm.models.cities
                                          )
                                            ? _vm._i(
                                                _vm.models.cities,
                                                item.Id
                                              ) > -1
                                            : _vm.models.cities
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$a = _vm.models.cities,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = item.Id,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.models,
                                                      "cities",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.models,
                                                      "cities",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.models,
                                                  "cities",
                                                  $$c
                                                )
                                              }
                                            },
                                            function($event) {
                                              return _vm.changeMain(
                                                item,
                                                $event
                                              )
                                            }
                                          ]
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "list-item--label",
                                          attrs: { for: "city-" + item.Id }
                                        },
                                        [_vm._v(_vm._s(item.Name))]
                                      ),
                                      _vm._v(" "),
                                      item.SubDistricts
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "chosen" },
                                              [
                                                _vm._v("Выбрано "),
                                                _c("span", [
                                                  _vm._v(_vm._s(item.selected))
                                                ]),
                                                _vm._v(" районов")
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "popup__dropdown",
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleDropdown(
                                                    item
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  item.SubDistricts
                                    ? _c(
                                        "ul",
                                        {
                                          class: {
                                            active: _vm.isDistrictExpanded
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "back test",
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleMobileDistrict()
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    "/Content/Images/arrow-left-grey2.svg",
                                                  alt: ""
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v("К списку районов")
                                              ])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(item.SubDistricts, function(
                                            itemChild
                                          ) {
                                            return _c("li", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.models.SubDistricts,
                                                    expression:
                                                      "models.SubDistricts"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id:
                                                    "SubDistrict-" +
                                                    itemChild.Id
                                                },
                                                domProps: {
                                                  value: itemChild.Id,
                                                  checked: Array.isArray(
                                                    _vm.models.SubDistricts
                                                  )
                                                    ? _vm._i(
                                                        _vm.models.SubDistricts,
                                                        itemChild.Id
                                                      ) > -1
                                                    : _vm.models.SubDistricts
                                                },
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$a =
                                                          _vm.models
                                                            .SubDistricts,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = itemChild.Id,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.models,
                                                              "SubDistricts",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.models,
                                                              "SubDistricts",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.models,
                                                          "SubDistricts",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function($event) {
                                                      return _vm.changeSubDistrict(
                                                        itemChild,
                                                        item,
                                                        $event
                                                      )
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  attrs: {
                                                    for:
                                                      "SubDistrict-" +
                                                      itemChild.Id
                                                  }
                                                },
                                                [_vm._v(_vm._s(itemChild.Name))]
                                              )
                                            ])
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm.currentTab === "districts"
                      ? _c("div", [
                          _c(
                            "ul",
                            { staticClass: "list" },
                            _vm._l(_vm.objects.districts, function(item) {
                              return _c(
                                "li",
                                {
                                  staticClass: "list-item list-item--parent",
                                  class: {
                                    active: _vm.activeDropdown === item.Id
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "list-item--parent__content"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.models.districts,
                                            expression: "models.districts"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "district-" + item.Id
                                        },
                                        domProps: {
                                          value: item.Id,
                                          checked: Array.isArray(
                                            _vm.models.districts
                                          )
                                            ? _vm._i(
                                                _vm.models.districts,
                                                item.Id
                                              ) > -1
                                            : _vm.models.districts
                                        },
                                        on: {
                                          change: [
                                            function($event) {
                                              var $$a = _vm.models.districts,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = item.Id,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.models,
                                                      "districts",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.models,
                                                      "districts",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.models,
                                                  "districts",
                                                  $$c
                                                )
                                              }
                                            },
                                            function($event) {
                                              return _vm.changeMain(
                                                item,
                                                $event
                                              )
                                            }
                                          ]
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "list-item--label",
                                          attrs: { for: "district-" + item.Id }
                                        },
                                        [_vm._v(_vm._s(item.Name))]
                                      ),
                                      _vm._v(" "),
                                      item.SubDistricts
                                        ? [
                                            _c(
                                              "div",
                                              { staticClass: "chosen" },
                                              [
                                                _vm._v("Выбрано "),
                                                _c("span", [
                                                  _vm._v(_vm._s(item.selected))
                                                ]),
                                                _vm._v(" районов")
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", {
                                              staticClass: "popup__dropdown",
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleDropdown(
                                                    item
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  item.SubDistricts
                                    ? _c(
                                        "ul",
                                        _vm._l(item.SubDistricts, function(
                                          itemChild
                                        ) {
                                          return _c("li", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.models.SubDistricts,
                                                  expression:
                                                    "models.SubDistricts"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                id:
                                                  "SubDistrict-" + itemChild.Id
                                              },
                                              domProps: {
                                                value: itemChild.Id,
                                                checked: Array.isArray(
                                                  _vm.models.SubDistricts
                                                )
                                                  ? _vm._i(
                                                      _vm.models.SubDistricts,
                                                      itemChild.Id
                                                    ) > -1
                                                  : _vm.models.SubDistricts
                                              },
                                              on: {
                                                change: [
                                                  function($event) {
                                                    var $$a =
                                                        _vm.models.SubDistricts,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = itemChild.Id,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.models,
                                                            "SubDistricts",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.models,
                                                            "SubDistricts",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.models,
                                                        "SubDistricts",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function($event) {
                                                    return _vm.changeSubDistrict(
                                                      itemChild,
                                                      item,
                                                      $event
                                                    )
                                                  }
                                                ]
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for:
                                                    "SubDistrict-" +
                                                    itemChild.Id
                                                }
                                              },
                                              [_vm._v(_vm._s(itemChild.Name))]
                                            )
                                          ])
                                        }),
                                        0
                                      )
                                    : _vm._e()
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "popup__bottom", class: { active: _vm.isExpanded } },
          [
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "btn btn-clean",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.resetSelected()
                    }
                  }
                },
                [_vm._v("Очистить")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn btn-main",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.hidePopup()
                    }
                  }
                },
                [_vm._v("Найти")]
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _vm.selectedIds.regionId
        ? _c("input", {
            attrs: { type: "hidden", name: "region" },
            domProps: { value: _vm.selectedIds.regionId }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedIds.districtIds.length
        ? _c("input", {
            attrs: { type: "hidden", name: "districtIds" },
            domProps: { value: _vm.selectedIds.districtIds.join(",") }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedIds.subDistrictIds
        ? _c("input", {
            attrs: { type: "hidden", name: "subdistrictIds" },
            domProps: { value: _vm.selectedIds.subDistrictIds.join(",") }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        staticClass: "custom-select",
        attrs: {
          loading: _vm.loading,
          items: _vm.auto,
          name: _vm.name,
          label: "Название новостройки",
          filter: _vm.compare,
          "hide-no-data": "",
          "menu-props": _vm.menuProps,
          "search-input": _vm.searchString
        },
        on: {
          "update:searchInput": function($event) {
            _vm.searchString = $event
          },
          "update:search-input": function($event) {
            _vm.searchString = $event
          }
        },
        model: {
          value: _vm.searchObject,
          callback: function($$v) {
            _vm.searchObject = $$v
          },
          expression: "searchObject"
        }
      }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "hidden", name: "ComplexName" },
        domProps: { value: _vm.searchObject }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import axios from 'axios';
import * as qs from 'qs';

/* global GlobalVars */
export default new Vuex.Store({
  state: {
    apiUrl: window.location.origin + '/api/',
    geocodeUrl: 'https://img11.address.ua/api/Maps/Geocode/',
    flatTypes: [],
    selected: {
      region: false,
      regionId: 1,
      districtIds: [],
      subDistrictIds: [],
    },
    locationObjects: {
      regions: [],
      cityDistricts: [],
      cities: [],
      districts: [],
      SubDistricts: [],
    },
    showPopup: false,
    countryCode: 'UA',
    globalVars: window.GlobalVars || {},
    SearchString: '',
    yandex: {
      Region: '',
      District: '',
      SubDistrict: '',
      Street: '',
      Building: '',
      Depend: false,
    },
    complexType: 1,
  },
  mutations: {
    setter(state, object) {
      Object.entries(object).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
    updateSelected(state, object) {
      Object.entries(object).forEach(([key, value]) => {
        Vue.set(state.selected, key, value);
      });
    },
  },
  actions: {
    sendRequest({state, commit}, request) {
      // let url = state.apiUrl + request.method;
      // axios.defaults.headers.common['Authorization'] = state.token;

      // axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

      let options = {
        method: request.method ? request.method : 'get',
        url: state.apiUrl + request.apiMethod,
        data: {},
      };

      if (request.url) {
        options.url = request.url;
      }

      if (request.responseType) {
        options.responseType = request.responseType;
      }

      // let options = Object.assign(def, request);
      if (request.body && request.type !== 'json') {
        options.data = qs.stringify(request.body);

        if (options.method === 'get') {
          options.url += '?' + options.data;
        }
      } else {
        // axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

        options.data = request.body;
      }

      console.log('request', options);
      return axios(options);
      // return axios.post(url, qs.stringify(request.body));
    },
    load() {
      return new Promise((resolve) => {
          if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', () => resolve());
          } else {
            resolve();
          }
      });
    }
  },
  getters: {

  }
});

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        staticClass: "custom-select",
        attrs: {
          loading: _vm.loading,
          items: _vm.auto,
          label: "Быстрый поиск",
          filter: _vm.compare,
          attach: "",
          "hide-no-data": "",
          "return-object": "",
          "search-input": _vm.searchString
        },
        on: {
          "update:searchInput": function($event) {
            _vm.searchString = $event
          },
          "update:search-input": function($event) {
            _vm.searchString = $event
          }
        },
        model: {
          value: _vm.searchObject,
          callback: function($$v) {
            _vm.searchObject = $$v
          },
          expression: "searchObject"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
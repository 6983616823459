import { render, staticRenderFns } from "./popupReminder.vue?vue&type=template&id=1ab23914&scoped=true&"
import script from "./popupReminder.vue?vue&type=script&lang=js&"
export * from "./popupReminder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ab23914",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/skipin/NetBeansProjects/Address/Address.Web/Assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1ab23914')) {
      api.createRecord('1ab23914', component.options)
    } else {
      api.reload('1ab23914', component.options)
    }
    module.hot.accept("./popupReminder.vue?vue&type=template&id=1ab23914&scoped=true&", function () {
      api.rerender('1ab23914', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "sources/vue/components/popups/auth/popupReminder.vue"
export default component.exports
<template>
  <div class="block-single block-dynamics" v-if="statistic && statistic.length">
    <div class="title h3">Динамика цены</div>

    <div class="block-dynamics__chart">
      <div ref="graph" class="graph"></div>
    </div>
  </div>
</template>

<script>
  import * as am4core from "@amcharts/amcharts4/core";
  import * as am4charts from "@amcharts/amcharts4/charts";
  import am4themes_animated from "@amcharts/amcharts4/themes/animated";
  import am4lang from "@amcharts/amcharts4/lang/ru_RU";

  am4core.useTheme(am4themes_animated);

  export default {
    name: "priceDynamics",
    data() {
      return {
        statistic: [],
        unique: [],
        configs: [
          {
            type: 1,
            text: 'Текущий объект',
            color: '#F1BE48',
          },
          {
            type: 2,
            text: 'Ср. цена в этом микрорайоне',
            color: '#27AE60',
          },
          {
            type: 3,
            text: 'Ср. цена в этом районе',
            color: '#F2994A',
          },
          {
            type: 4,
            text: 'Ср. цена в регионе',
            color: '#9B51E0',
          },
        ],
        charts: [],
        chart: {},
      }
    },
    mounted() {
      this.getStatistic();
    },
    computed: {
      globalVars() {
        return this.$store.state.globalVars;
      },
      complexId() {
        return this.globalVars.complexId || this.getIdFromGallery();
      },
    },
    methods: {
      getStatistic() {
        let data = {
          apiMethod: 'ConstructionComplex/MonthlyStatistic',
          body: {
            complexId: this.complexId,
          },
        };

        this.$store.dispatch('sendRequest', data).then((res) => {
          console.log('getStatistic', res);

          if (res.data) {
            this.statistic = res.data.PriceTrends;

            this.generateCharts(this.statistic);
          }
        });
      },
      generateCharts(data) {
        this.unique = [...new Set(data.map(item => item.Date))];
        // console.log(this.unique);

        this.unique.forEach(item => {
          let items = data.filter(i => i.Date === item);
          // console.log(items);
          let chart = {
            "date": new Date(item),
          };

          items.forEach(i => {
            let key = 'type' + i.StatisticTypeId;

            chart[key] = Math.round(i.Value);
          });

          this.charts.push(chart);
        });

        if (this.charts.length) {
          setTimeout(()=>{
            this.initChart();
          });
        }
      },
      initChart() {
        let chart = am4core.create(this.$refs.graph, am4charts.XYChart);
        this.chart = chart;

        chart.language.locale = am4lang;
        this.chart.data = this.charts;

        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 50;
        dateAxis.renderer.grid.template.location = 0.5;
        dateAxis.startLocation = 0.5;
        dateAxis.endLocation = 0.5;


        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.labels.template.adapter.add("text", function (text) {
          return "$" + text;
        });

        // Create series
        this.createSeries();

        // Add cursor
        chart.cursor = new am4charts.XYCursor();

        // add legend
        chart.legend = new am4charts.Legend();
      },
      createSeries() {
        let obj = this.charts[0];

        Object.entries(obj).forEach(([key, value]) => {
          if (key !== 'date') {
            let id = parseInt(key.replace('type', ''));
            let config = this.configs.find(i => i.type === id);

            let series = this.chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = key;
            series.dataFields.dateX = "date";
            series.name = config.text;

            series.stroke = am4core.color(config.color); // red
            series.strokeWidth = 3; // 3px
            series.fill = am4core.color(config.color);

            series.tooltipText = "{name}: [bold]${valueY}[/]";

            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = am4core.color(config.color);

            series.tooltip.getStrokeFromObject = true;
            series.tooltip.background.strokeWidth = 3;

            series.fillOpacity = 0.2;

            // static
            series.legendSettings.labelText = `${config.text}:`;
            series.legendSettings.valueText = "{valueY.close}";

            // hovering
            series.legendSettings.itemLabelText = `${config.text}:`;
            series.legendSettings.itemValueText = "{valueY}";
          }
        });
      },
      getIdFromGallery() {
        let reg = /\/complex\/([0-9]+)\/bg\//;
        let link = document.querySelector('.block-info .complex-fancybox');

        if (link) {
          let href = link.getAttribute('href');
          let result = reg.exec(href);

          if (result.length > 1) {
            return parseInt(result[1]);
          }
        }
        return false;
      },
    },
  }
</script>

<style lang="scss" scoped>
  .graph {
    width: 100%;
    height: 400px;
    margin-top: 30px;
  }
</style>

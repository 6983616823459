<template>
  <div class="popup popup--filters"
       :class="{'popup--active': popupVisible}"
       @click.self="hidePopup()">
    <div class="popup__block popup__wrapper">
      <div @click="hidePopup()" class="popup__close"></div>
      <div class="popup__left">
        <div class="title">Область</div>
        <div class="popup--filters__list">
          <div class="item"
               v-for="item in objects.regions"
               :key="item.Id"
               :class="{active: activeRegion === item}"
               @click="toggleRegion(item)">
            <span>{{ item.Name }}</span>
          </div>
        </div>
      </div>
      <div class="popup__right" v-bind:class="{ 'active': isExpanded }">
        <div class="back" @click="toggleMobileRegion()">
          <img src="/Content/Images/arrow-left-grey2.svg" alt="" />
          <span>К списку областей</span>
        </div>
        <ul class="tabs">
          <li class="tab"
              v-for="tab in tabs[tabsType]"
              :class="{active : currentTab === tab.id}"
              :key="tab.id"
              @click="currentTab = tab.id">
            <span>{{ tab.name }}</span>
          </li>
        </ul>
        <div class="popup__content">
          <transition name="fade" mode="out-in" tag="div">
            <div v-if="currentTab === 'area'">
              <ul class="list">
                <li class="list-item list-item--parent"
                    v-for="item in objects.cityDistricts"
                    v-bind:class="[{'active': activeDropdown === item.Id},{'activeChild': isCityDistrictExpanded}]">
                  <div class="list-item--parent__content">
                    <input type="checkbox" :id="'cityDistrict-'+item.Id"
                           @change="changeMain(item, $event)"
                           :value="item.Id" v-model="models.cityDistricts" />
                    <label :for="'cityDistrict-'+item.Id" class="list-item--label">{{item.Name}}</label>

                    <template v-if="item.SubDistricts">
                      <div class="chosen">Выбрано <span>{{ item.selected }}</span> микрорайонов</div>
                      <div @click="toggleDropdown(item)"
                           class="popup__dropdown"></div>
                    </template>
                  </div>
                  <ul v-if="item.SubDistricts" v-bind:class="{ 'active': isCityDistrictExpanded }">
                    <div class="back" @click="toggleMobileCityDistrict()">
                      <img src="/Content/Images/arrow-left-grey2.svg" alt="" />
                      <span>К списку районов</span>
                    </div>
                    <li v-for="itemChild in item.SubDistricts">
                      <input type="checkbox" :id="'SubDistrict-'+itemChild.Id"
                             :value="itemChild.Id" v-model="models.SubDistricts"
                             @change="changeSubDistrict(itemChild, item, $event)" />
                      <label :for="'SubDistrict-'+itemChild.Id">{{itemChild.Name}}</label>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div v-else-if="currentTab === 'metro'">
              <div class="metro-wrapper">
                <ul class="list list-metro">
                  <li class="list-item list-item--parent list-item--metro">
                    <div class="list-item--parent__content">
                      <input type="checkbox" id="m1" />
                      <label for="m1" class="list-item--label">
                        <img src="/Content/Images/metro-green.svg" alt="" />Сырецко-Печерская
                      </label>
                      <div class="chosen">Выбрано 7 станций</div>
                      <div class="dropdown"></div>
                    </div>
                    <ul>
                      <li>
                        <input type="checkbox" id="m11" />
                        <label for="m11">Голосеево</label>
                      </li>
                      <li>
                        <input type="checkbox" id="m12" />
                        <label for="m12">Демеевка </label>
                      </li>
                      <li>
                        <input type="checkbox" id="m13" />
                        <label for="m13">Корчеватое</label>
                      </li>
                      <li>
                        <input type="checkbox" id="m14" />
                        <label for="m14">с. Чапаевка</label>
                      </li>
                      <li>
                        <input type="checkbox" id="m15" />
                        <label for="m15">Саперная Слободка</label>
                      </li>
                      <li>
                        <input type="checkbox" id="m16" />
                        <label for="m16">Теремки-1 </label>
                      </li>
                      <li>
                        <input type="checkbox" id="m17" />
                        <label for="m17">Теремки-2</label>
                      </li>
                    </ul>
                  </li>
                  <li class="list-item list-item--parent list-item--metro">
                    <div class="list-item--parent__content">
                      <input type="checkbox" id="m2" />
                      <label for="m2" class="list-item--label">
                        <img src="/Content/Images/metro-blue.svg" alt="" />Куренёвско-Красноармейская
                      </label>
                      <div class="chosen">Выбрано 7 станций</div>
                      <div class="dropdown"></div>
                    </div>
                    <ul>
                      <li>
                        <input type="checkbox" id="m21" />
                        <label for="m21">Голосеевский</label>
                      </li>
                      <li>
                        <input type="checkbox" id="m22" />
                        <label for="m22">Голосеевский</label>
                      </li>
                      <li>
                        <input type="checkbox" id="m23" />
                        <label for="m23">Голосеевский</label>
                      </li>
                      <li>
                        <input type="checkbox" id="m24" />
                        <label for="m24">Голосеевский</label>
                      </li>
                      <li>
                        <input type="checkbox" id="m25" />
                        <label for="m25">Голосеевский</label>
                      </li>
                    </ul>
                  </li>
                  <li class="list-item list-item--parent list-item--metro">
                    <div class="list-item--parent__content">
                      <input type="checkbox" id="m3" />
                      <label for="m3" class="list-item--label">
                        <img src="/Content/Images/metro-red.svg" alt="" />Святошинско-Броварская
                      </label>
                      <div class="chosen">Выбрано 7 станций</div>
                      <div class="dropdown"></div>
                    </div>
                    <ul>
                      <li>
                        <input type="checkbox" id="m31" />
                        <label for="m31">Голосеевский</label>
                      </li>
                      <li>
                        <input type="checkbox" id="m32" />
                        <label for="m32">Голосеевский</label>
                      </li>
                      <li>
                        <input type="checkbox" id="m33" />
                        <label for="m33">Голосеевский</label>
                      </li>
                      <li>
                        <input type="checkbox" id="m34" />
                        <label for="m34">Голосеевский</label>
                      </li>
                      <li>
                        <input type="checkbox" id="m35" />
                        <label for="m35">Голосеевский</label>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="magnifier">
                  <div class="large"></div>
                  <img class="small" src="/Content/Images/metro-map.png" width="360" />
                </div>
              </div>
            </div>
            <div v-else-if="currentTab === 'cities'">
              <ul class="list">
                <li class="list-item list-item--parent"
                    v-for="item in objects.cities"
                    v-bind:class="{'active': activeDropdown === item.Id, 'activeChild': isDistrictExpanded}">
                  <div class="list-item--parent__content">
                    <input type="checkbox" :id="'city-'+item.Id"
                           @change="changeMain(item, $event)"
                           :value="item.Id" v-model="models.cities" />
                    <label :for="'city-'+item.Id" class="list-item--label">{{item.Name}}</label>

                    <template v-if="item.SubDistricts">
                      <div class="chosen">Выбрано <span>{{ item.selected }}</span> районов</div>
                      <div @click="toggleDropdown(item)" class="popup__dropdown"></div>
                    </template>
                  </div>
                  <ul v-if="item.SubDistricts" v-bind:class="{ 'active': isDistrictExpanded }">
                    <div class="back test" @click="toggleMobileDistrict()">
                      <img src="/Content/Images/arrow-left-grey2.svg" alt="" />
                      <span>К списку районов</span>
                    </div>
                    <li v-for="itemChild in item.SubDistricts">
                      <input type="checkbox" :id="'SubDistrict-'+itemChild.Id"
                             :value="itemChild.Id" v-model="models.SubDistricts"
                             @change="changeSubDistrict(itemChild, item, $event)" />
                      <label :for="'SubDistrict-'+itemChild.Id">{{itemChild.Name}}</label>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div v-else-if="currentTab === 'districts'">
              <ul class="list">
                <li class="list-item list-item--parent"
                    v-for="item in objects.districts"
                    :class="{active: activeDropdown === item.Id}">
                  <div class="list-item--parent__content">
                    <input type="checkbox" :id="'district-'+item.Id"
                           @change="changeMain(item, $event)"
                           :value="item.Id" v-model="models.districts" />
                    <label :for="'district-'+item.Id" class="list-item--label">{{item.Name}}</label>

                    <template v-if="item.SubDistricts">
                      <div class="chosen">Выбрано <span>{{ item.selected }}</span> районов</div>
                      <div @click="toggleDropdown(item)" class="popup__dropdown"></div>
                    </template>
                  </div>
                  <ul v-if="item.SubDistricts">
                    <li v-for="itemChild in item.SubDistricts">
                      <input type="checkbox" :id="'SubDistrict-'+itemChild.Id"
                             :value="itemChild.Id" v-model="models.SubDistricts"
                             @change="changeSubDistrict(itemChild, item, $event)" />
                      <label :for="'SubDistrict-'+itemChild.Id">{{itemChild.Name}}</label>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </transition>
        </div>

      </div>
      <div class="popup__bottom" v-bind:class="{ 'active': isExpanded }">
        <!--        <div class="result">Выбрано: </div>-->
        <div>
          <div class="btn btn-clean" @click.prevent="resetSelected()">Очистить</div>
          <div class="btn btn-main" @click.prevent="hidePopup()">Найти</div>
        </div>
      </div>
    </div>
    <input type="hidden" name="region" v-if="selectedIds.regionId" :value="selectedIds.regionId">
    <input type="hidden" name="districtIds" v-if="selectedIds.districtIds.length"
           :value="selectedIds.districtIds.join(',')">
    <input type="hidden" name="subdistrictIds" v-if="selectedIds.subDistrictIds"
           :value="selectedIds.subDistrictIds.join(',')">
  </div>
</template>

<script>
  import * as qs from "qs";

  export default {
    name: "popupLocation",
    watch: {
      models: {
        deep: true,
        handler: function () {
          this.updateOut();
        }
      },
      objects: {
        deep: true,
        handler: function (val) {
          this.$store.commit('setter', {
            locationObjects: val,
          });
        }
      }
    },
    data() {
      return {
        // regions: [],
        search_block: false,
        tabs: {
          capital: [
            {id: 'area', name: 'Районы'},
            // {id: 'metro', name: 'Метро'},
          ],
          districts: [
            {id: 'cities', name: 'Города'},
            {id: 'districts', name: 'Областные районы'},
          ],
        },
        windowWidth: window.innerWidth,
        activeRegion: false,
        isExpanded: false,
        isDistrictExpanded: false,
        isCityDistrictExpanded: false,
        activeDropdown: false,
        tabsType: 'capital',
        currentTab: false,
        objects: {
          regions: [],
          cityDistricts: [],
          cities: [],
          districts: [],
          SubDistricts: [],
        },
        models: {
          cityDistricts: [],
          SubDistricts: [],
          cities: [],
          districts: [],
        },
        out: {
          districtIds: [],
          subDistrictIds: [],
        }
      }
    },
    created() {
      this.getRegions();
    },
    computed: {
      selectedIds: {
        get() {
          return this.$store.state.selected;
        },
        set(value) {
          this.$store.commit('setter', {selected: value})
        }
      },
      globalVars() {
        return this.$store.state.globalVars;
      },
      popupVisible: {
        get() {
          let visible = this.$store.state.showPopup;

          if (visible) {
            document.body.classList.add('hide-scroll');
          } else {
            document.body.classList.remove('hide-scroll');
          }

          return visible;
        },
        set(value) {
          this.$store.commit('setter', {
            showPopup: value,
          });
        },
      },
    },
    mounted() {
      let button = document.querySelector('[data-vue=modal_location]');

      if (button) {
        button.addEventListener('click', () => this.showPopup());
      }

    },
    methods: {
      showPopup() {
        // console.info('showPopup');
        this.popupVisible = !this.popupVisible;
      },
      hidePopup() {
        this.popupVisible = false;

        this.setLabels();
      },
      toggleDropdown(item) {
        switch (item.type) {
          case 'cityDistricts':
            this.toggleMobileCityDistrict();
            break;
          case 'cities':
            this.toggleMobileDistrict();
            break;
          case 'districts':
            this.toggleMobileDistrict();
            break;
        }

        if (this.activeDropdown !== item.Id) {
          this.activeDropdown = item.Id;
        } else {
          this.activeDropdown = false;
        }
      },
      toggleRegion(item) {
        this.toggleMobileRegion();

        if (item !== this.activeRegion) {
          this.resetSelected();
          this.activeRegion = item;

          this.tabsType = item.Id === 1 ? 'capital' : 'districts';
          this.currentTab = this.tabs[this.tabsType][0].id;

          if (item.Id === 1) {
            this.getCityDistricts(item.Id);
          } else {
            this.getCities(item.Id).then(() => {
              this.getDistricts(item.Id);
            });
          }

          this.$store.commit('updateSelected', {
            regionId: item.Id,
          });

          this.$store.commit('updateSelected', {
            region: item,
          });
        }
      },
      toggleMobileRegion() {
        if (window.innerWidth < 1025) {
          this.isExpanded = !this.isExpanded;
        }
      },
      toggleMobileDistrict() {
        if (window.innerWidth < 1025) {
          this.isDistrictExpanded = !this.isDistrictExpanded;
        }
      },
      toggleMobileCityDistrict() {
        if (window.innerWidth < 1025) {
          this.isCityDistrictExpanded = !this.isCityDistrictExpanded;
        }
      },
      getRegions() {
        let data = {
          apiMethod: 'region/getByCountry',
          body: {
            country: this.globalVars.CountryId ? parseInt(this.globalVars.CountryId) : 1,
          },
        };

        this.$store.dispatch('sendRequest', data).then((res) => {
          let regions = res.data;
          if (!Array.isArray(regions)) {
            regions = Object.values(regions);
          }

          this.objects.regions = regions;
          if (this.globalVars.Region) {
            let region = this.objects.regions.find(i => i.Id === this.globalVars.Region);
            this.toggleRegion(region);
          } else if (this.objects.regions.length) {
            this.toggleRegion(this.objects.regions[0]);
          }
        });
      },
      async getCityDistricts(regionId) {
        let data = {
          apiMethod: 'region/getCityDistricts',
          body: {
            regionId: regionId,
          },
        };

        return this.$store.dispatch('sendRequest', data).then((res) => {
          let districts = res.data;
          districts.map(item => {
            if (item.SubDistricts) {
              this.objects.SubDistricts = this.objects.SubDistricts.concat(item.SubDistricts);
            }

            item.selected = this.countSelected(item.SubDistricts);
            item.type = 'cityDistricts';
          });

          this.objects.cityDistricts = res.data;
          this.parseQuery();
        });
      },
      async getCities(regionId) {
        let data = {
          apiMethod: 'region/getCities',
          body: {
            regionId: regionId,
          },
        };

        return this.$store.dispatch('sendRequest', data).then((res) => {
          let cities = res.data;
          cities.map(item => {
            if (item.SubDistricts) {
              this.objects.SubDistricts = this.objects.SubDistricts.concat(item.SubDistricts);
            }

            item.selected = this.countSelected(item.SubDistricts);
            item.type = 'cities';
          });

          this.objects.cities = cities;
        });
      },
      async getDistricts(regionId) {
        let data = {
          apiMethod: 'region/getDistricts',
          body: {
            regionId: regionId,
          },
        };

        return this.$store.dispatch('sendRequest', data).then((res) => {

          let districts = res.data;
          districts.map(item => {
            if (item.SubDistricts) {
              this.objects.SubDistricts = this.objects.SubDistricts.concat(item.SubDistricts);
            }

            item.selected = this.countSelected(item.SubDistricts);
            item.type = 'districts';
          });

          this.objects.districts = districts;
          this.parseQuery();
        });
      },
      changeMain(item, $event) {
        let checked = $event.target.checked;

        this.selectItem(item, checked);
      },
      changeSubDistrict(item, parent, $event) {
        let checked = $event.target.checked;

        this.selectItem(item, checked);

        // console.log('selectItem', parent);
        this.checkSubDistricts(parent);
      },
      selectItem(item, select) {
        // console.log('selectItem', item);
        let type = item.type || 'SubDistricts';
        let index = this.models[type].findIndex(v => v === item.Id);

        if (select && index < 0) {
          this.models[type].push(item.Id);
        }

        if (!select && index >= 0) {
          this.models[type].splice(index, 1);
        }
        item.selected = select;

        if (item.SubDistricts) {
          item.SubDistricts.forEach((child) => {
            this.selectItem(child, select);
          });

          // console.log('selectItem', item);
          this.checkSubDistricts(item);
        }

        if (item.type === 'districts') {
          let childs = this.objects.cities.filter(i => i.DistrictId === item.Id);
          childs.forEach((child) => {
            this.selectItem(child, select);
          });
        } else if (item.type === 'cities') {
          this.checkDistrictCities(item.DistrictId);
        }

      },
      checkDistrictCities(id) {
        let cities = this.objects.cities.filter(i => i.DistrictId === id);
        let selected = cities.filter(i => i.selected);
        let type = 'districts';


        let select = cities.length === selected.length;
        let district = this.objects[type].find(i => i.Id === id);
        if (district) {
          let index = this.models[type].findIndex(v => v === district.Id);

          if (select && index < 0) {
            this.models[type].push(district.Id);
          }

          if (!select && index >= 0) {
            this.models[type].splice(index, 1);
          }
        }
      },
      checkSubDistricts(item) {
        // console.log('checkSubDistricts', item);
        let index = this.models[item.type].findIndex(v => v === item.Id);
        item.selected = this.countSelected(item.SubDistricts);

        let select = item.selected === item.SubDistricts.length;
        if (select && index < 0) {
          this.models[item.type].push(item.Id)
        } else if (!select && index >= 0) {
          this.models[item.type].splice(index, 1);
        }
      },
      countSelected(items) {
        if (items) {
          let selected = items.filter(i => i.selected);

          return selected.length;
        } else {
          return false;
        }
      },
      updateOut() {
        // console.log('updateOut');
        this.out.districtIds = [];
        this.out.subDistrictIds = [];

        this.out.subDistrictIds = [...this.models.cities, ...this.models.SubDistricts];
        this.out.districtIds = [...this.models.districts, ...this.models.cityDistricts];

        Object.entries(this.models).forEach(([type, items]) => {
          if (type !== 'SubDistricts') {
            items.forEach(item => {
              this.filterChild(item, type);
            });
          }
        });

        this.$store.commit('updateSelected', {
          districtIds: this.out.districtIds,
          subDistrictIds: this.out.subDistrictIds,
        });
      },
      resetSelected() {
        Object.entries(this.models).forEach(([key, value]) => {
          this.models[key] = [];
        });
      },
      filterChild(id, type) {
        if (type === 'districts') {
          let cities = this.objects.cities.filter(i => i.DistrictId === id);
          let selected = cities.filter(i => i.selected);

          if (cities.length === selected.length) {
            let child = cities.map(i => i.Id);

            this.out.subDistrictIds = this.out.subDistrictIds.filter(i => !child.includes(i));
          }
        } else {
          let item = this.objects[type].find(i => i.Id === id);

          if (item.SubDistricts && item.selected === item.SubDistricts.length) {
            let child = item.SubDistricts.map(i => i.Id);

            this.out.subDistrictIds = this.out.subDistrictIds.filter(i => !child.includes(i));
          }

          if (type === 'cities') {
            let city = this.objects.cities.find(i => i.Id === id);

            if (city.SubDistricts && city.SubDistricts.length === city.selected) {
              this.out.subDistrictIds = this.out.subDistrictIds.filter(i => i !== id);

              this.out.districtIds.push(id);
            }
          }
        }
      },
      parseQuery() {
        let globalVars = this.globalVars;

        let args = qs.parse(window.location.search.slice(1));
        // send_btn.setAttribute('disabled', 'disabled');
        let search_string = document.querySelector('#SearchString');

        let districtIds = globalVars.DistrictIds;
        let subDistrictIds = globalVars.SubDistrictIds;

        let objects = Object.assign({}, this.objects);
        // console.log('parseQuery', objects);
        // console.log('parseQuery', globalVars);

        setTimeout(() => {
        Object.entries(objects).forEach(([key, items]) => {
          if (key !== 'regions') {
            items.forEach(item => {
              if (districtIds.includes(item.Id)) {
                if (key === 'cityDistricts' || key === 'districts'
                  || key === 'cities' && item.SubDistricts) {
                  this.selectItem(item, true);
                }
              }

              if (subDistrictIds.includes(item.Id)) {
                if (key === 'cities' || key === 'SubDistricts') {
                  this.selectItem(item, true);
                }

                if (key === 'SubDistricts') {
                  let parents = [...objects.cities, ...objects.cityDistricts];
                  let parent = parents.find(i => i.Id === item.DistrictId);

                  if (parent) {
                    this.checkSubDistricts(parent);
                  }
                }
              }
            });
          }
        });

        setTimeout(() => {
          this.setLabels();
        }, 300);

        if (args.searchstring) {
          search_string.value = args.searchstring;
        }
        }, 1200);
      },
      setLabels() {
        let search_block = document.querySelector('.search_block');
        let search_string = document.querySelector('#SearchString');

        let send_btn = document.querySelector('.send.btn-main');
        let btn_main = document.querySelector('.btn.btn-main');

        if (search_block) {
          this.search_block = true;
        }

        let input_val = document.querySelector('[data-vue=modal_location] input');

        if (input_val) {
          let names = [];
          names.push( this.activeRegion.Name );

          Object.entries(this.objects).forEach(([key, items]) => {
            if (key !== 'regions') {
              items.forEach(item => {
                if (this.selectedIds.districtIds.includes(item.Id)) {
                  if (key === 'cityDistricts' || key === 'districts'
                    || key === 'cities' && item.SubDistricts) {
                    names.push(item.Name);
                  }
                }

                if (this.selectedIds.subDistrictIds.includes(item.Id)) {
                  if (key === 'cities' || key === 'SubDistricts') {
                    names.push(item.Name);
                  }
                }
              });
            }
          });

          if (names.length && input_val) {
            input_val.value = names.join(', ');
          }

          if (search_string) {
            search_string.value = this.activeRegion.Name + ', ';
          }
        }

        if (send_btn) {
          send_btn.removeAttribute('disabled');
        }
        if (btn_main) {
          btn_main.removeAttribute('disabled');
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../../../../../css_scss/scss/setup';

  .popup {
    &__wrapper {
      height: 500px;
      width: 889px;
      padding: 0;
      display: grid;
      grid-template-columns: 240px 648px;
      grid-template-rows: auto 96px;

      @include tablet {
        height: auto;
        min-height: 100vh;
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }
    }

    &__left {
      padding: 0 0 0 42px;
      grid-area: 1/1/2/2;

      @include tablet {
        width: 100vw;
        padding: 0;
        grid-area: 1/1/2/2;
      }
    }

    &__right {
      grid-area: 1/2/2/3;

      @include tablet {
        position: fixed;
        width: 100vw;
        top: 45px;
        background-color: $c-white;
        z-index: -1;
        transform: translateX(100vw);
        transition: $transition;
      }

      &.active {
        @include tablet {
          z-index: 1;
          transform: translateX(0);
          transition: $transition;
        }
      }

      .back {
        display: none;
        align-items: center;
        padding: 16px 32px 0;

        @include tablet {
          display: flex;
        }

        span {
          font-family: $font;
          font-weight: 500;
          font-size: 12px;
          margin-left: 18px;
        }

      }
    }

    &__bottom {
      padding: 28px 24px 28px 42px;
      grid-area: 2/1/3/3;
      border-top: 1px solid #E3E3E3;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include tablet {
        display: flex;
        position: fixed;
        bottom: 0;
        background-color: white;
        width: 100vw;
        padding: 24px 32px;
        z-index: 2;
        transform: translateX(100vw);
        transition: $transition;
      }

      &.active {
        transform: translateX(0);
        transition: $transition;
      }

      div {
        display: flex;
        align-items: center;

        @include tablet {
          flex-direction: row-reverse;
          justify-content: center;
          width: 100%;
        }
      }

      .result {
        font-size: 13px;
        line-height: 12px;
      }

      button {
        @include tablet {
          width: 50%;
        }
      }

    }

    &__content {
      max-height: 345px;
      overflow: auto;

      @include tablet {
        height: 100vh;
        max-height: calc(100vh - 208px);
      }
    }

    &__close {
      @include tablet {
        top: 12px;
        right: 12px;
      }
    }

  }

  .popup--filters {
    .title {
      font-weight: 500;
      font-size: 13px;
      line-height: 13px;
      color: #000000;
      padding-bottom: 12px;
      padding-top: 34px;
      margin-right: 2px;
      border-right: 1px solid #e3e3e3;

      @include tablet {
        padding: 16px 0;
        text-align: center;
        border-right: none;
        border-bottom: 1px solid #e3e3e3;
      }
    }

    .popup--filters__list {
      display: grid;
      max-height: 345px;
      overflow-y: auto;
      overflow-x: hidden;

      @include tablet {
        padding: 0 32px;
        max-height: calc(100vh - 44px);
      }

      .item {
        position: relative;
        padding: 10px 0;
        cursor: pointer;
        border-right: 1px solid #e3e3e3;
        transition: $transition;

        @include tablet {
          border-right: none;
        }

        &:last-child {
          @include tablet {
            padding-bottom: 32px;
          }
        }

        span {
          display: inline;
          position: relative;
          margin-right: auto;
          width: auto;
          font-size: 13px;
          line-height: 13px;
          color: #000000;
          transition: .5s all ease;

          &:after {
            content: '';
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background-color: #F1BE48;
            margin-top: 2px;
            opacity: 0;
            transition: .5s all ease;
          }

          &:hover {
            &:after {
              opacity: 1;
              transition: .5s all ease;
            }

          }
        }

        &.active {
          span {
            &:after {
              opacity: 1;
              transition: .5s all ease;
            }

          }

          &:after {
            opacity: 1;
          }

        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 10px;
          right: -1px;
          left: unset;
          z-index: 1;
          width: 10px;
          height: 20px;
          background: url(/Content/Images/triangle-choose.svg) no-repeat center;
          opacity: 0;

          @include tablet {
            background: url(/Content/Images/caret-down.svg) no-repeat center;
            transform: rotate(-90deg);
            opacity: 1;
            z-index: 0;
          }
        }

      }
    }

    .tabs {
      background-color: #ffffff;
      padding: 38px 0 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-bottom: 1px solid #e3e3e3;
      border-radius: 0;
      margin-left: -2px;

      @include tablet {
        padding: 24px 0 0;
      }

      .tab {
        list-style: none;
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        outline: none;
        padding: 0 5px 10px 5px;
        transition: .5s all ease;
        float: left;
        position: relative;
        top: 1px;
        margin: 0 10px 0 0;
        background-color: transparent;
        border: none;
        padding: 0;
        outline: none;
        white-space: nowrap;
        cursor: pointer;
        transition: $transition;

        @include tablet {
          &:last-child {
            text-align: center;
            margin: 0 10px 0 20px;
          }
        }

        span {
          margin-bottom: 9px;
          display: block;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          height: 4px;
          width: 100%;
          background-color: #F1BE48;
          bottom: 0;
          opacity: 0;
          transition: $transition;
        }

        &.active {
          &:after {
            opacity: 1;
            transition: $transition;
          }

        }
      }

      &-panel {
        max-height: 345px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0;
      }

    }

    .list {
      display: inline-block;
      column-count: 2;
      padding: 22px 0 0 32px;

      @include tablet {
        column-count: 1;
        width: 100%;
        padding: 22px 32px 0;
      }

      &-item {
        &--parent {
          margin-right: 86px;
          margin-bottom: 13px;
          break-inside: avoid;
          transition: .5s all ease;

          @include tablet {
            width: 100%;
            margin-right: 32px;
          }

          &__content {
            position: relative;

            .popup__dropdown {
              position: absolute;
              display: block;
              outline: 0;
              border: 0;
              top: 0px;
              right: 0px;
              width: 20px;
              height: 20px;
              background: url("/Content/Images/caret-down.svg") no-repeat center;
              cursor: pointer;
              transition: $transition;

              @include tablet {
                transform: rotate(-90deg);
              }
            }

            .chosen {
              font-size: 9px;
              line-height: 9px;
              color: #686868;
              margin: 3px 0 3px 30px;
              transition: .5s all ease;
            }

          }

          ul {
            height: 0;
            padding: 0 0 0 31px;
            opacity: 0;
            transition: .5s all ease;

            li {
              margin-bottom: 13px;

              label {
                font-size: 12px;
              }

              @include tablet {
                padding: 0 32px;
                margin-bottom: 28px;
              }

            }

            .back {
              @include tablet {
                padding: 16px 32px;
                border-bottom: 1px solid #E3E3E3;
                margin-bottom: 16px;
              }
            }

          }

          &.active {
            ul {
              height: auto;
              padding: 13px 0 0 31px;
              opacity: 1;
              transition: $transition;

              @include tablet {
                height: 0;
                padding: 0;
                opacity: 0;
              }
            }

            .list-item--parent__content {
              .popup__dropdown {
                transition: $transition;
                transform: rotate(180deg);

                @include tablet {
                  transform: rotate(-90deg);
                }
              }

            }
          }

          &.active.activeChild {
            ul {
              @include tablet {
                height: 0;
                opacity: 0;
                width: 100vw;
                top: 0;
                left: 0;
                position: fixed;
                padding: 0;
                background-color: $c-white;
                opacity: 0;
                z-index: -1;
                transform: translateX(100vw);
                transition: $transition;
              }

              &.active {
                height: 100vh;
                max-height: calc(100vh - 125px);
                overflow-y: auto;
                opacity: 1;
                z-index: 3;
                transform: translateX(0);
                transition: $transition;
              }

            }
          }
        }

        &--metro {
          margin-bottom: 25px;
          margin-right: 17px;

          .list-item--label {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 13px;
            line-height: 12px;
            max-width: 208px;

            img {
              margin-right: 6px;
            }

            &:before {
              margin-right: 8px;
            }

          }

          .chosen {
            margin: 4px 0 3px 47px;
          }

        }
      }

      &-metro {
        column-count: 1;
      }

      input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
      }

      label {
        position: relative;
        display: flex;
        cursor: pointer;
        font-weight: normal;
        font-size: 13px;

        &:before {
          content: '';
          -webkit-appearance: none;
          background-color: transparent;
          min-width: 16px;
          min-height: 16px;
          width: 16px;
          height: 16px;
          box-sizing: border-box;
          border: 1px solid #E3E3E3;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 13px;
          transition: .5s all ease;
        }
      }

      input:checked + label:before {
        background: url("/Content/Images/checkbox-checked.svg") #F1BE48 no-repeat center;
      }

    }

    .metro-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    ::-webkit-scrollbar-track {
      background-color: #ffffff;
    }

    ::-webkit-scrollbar {
      width: 2px;
      background-color: #ffffff;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #F1BE48;
    }
  }
</style>

<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .hide-scroll {
    overflow: hidden;
    height: 100vh;
  }
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup__block" }, [
    _c("div", { staticClass: "popup__title block-heading" }, [
      _vm._v("Напомнить пароль")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "popup__text" }, [
      _vm._v("\n    Введите email, который вы регистрировали на сайте\n  ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "popup__form login-form" }, [
      _c("div", { staticClass: "login-form__input-wrap" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.models.email,
              expression: "models.email"
            }
          ],
          staticClass: "feedback__input",
          class: { error: _vm.$v.models.email.$error },
          attrs: { type: "email", placeholder: "Email" },
          domProps: { value: _vm.models.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.models, "email", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm.$v.models.email.$error
          ? _c("div", { staticClass: "form-field__error" }, [
              _vm._v(_vm._s(_vm.getErrorMessage(_vm.$v.models.email)))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "login-form__footer" }, [
        _c("div", { staticClass: "login-form__submit" }, [
          _c(
            "button",
            {
              staticClass: "btn-main",
              attrs: { type: "button" },
              on: { click: _vm.submit }
            },
            [_vm._v("Сбросить пароль")]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.success
        ? _c("div", { staticClass: "login-form__success" }, [
            _vm._v(_vm._s(_vm.successMessage))
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? _c("div", { staticClass: "login-form__errors" }, [
            _vm._v(_vm._s(_vm.errorMessage))
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
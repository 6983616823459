<template>
  <div class="popup__block">
    <div class="popup__title block-heading">Напомнить пароль</div>
    <div class="popup__text">
      Введите email, который вы регистрировали на сайте
    </div>

    <div class="popup__form login-form">
      <div class="login-form__input-wrap">
        <input type="email" placeholder="Email"
               class="feedback__input"
               :class="{error: $v.models.email.$error}"
               v-model="models.email">

        <div class="form-field__error" v-if="$v.models.email.$error">{{ getErrorMessage($v.models.email) }}</div>
      </div>

      <div class="login-form__footer">
        <div class="login-form__submit">
          <button type="button" @click="submit" class="btn-main">Сбросить пароль</button>
        </div>
      </div>

      <div class="login-form__success" v-if="success">{{ successMessage }}</div>
      <div class="login-form__errors" v-if="error">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
  import {required, email} from 'vuelidate/lib/validators';

  export default {
    name: "popupReminder",
    data() {
      return {
        success: false,
        error: false,
        successMessage: 'Пароль отправлен вам на почту.',
        errorMessage: 'При запросе или во время возвращения данных произошел сбой',
        models: {
          email: '',
        },
        apiUrl: "/WebServices/Notification.asmx/SendFeedback",
      }
    },
    validations: {
      models: {
        email: {
          required,
          email,
        },
      },
    },
    mounted() {
    },
    methods: {
      submit() {
        console.log('submit!');

        this.$v.$touch();

        if (this.$v.$invalid) {
          this.error = true;
        } else {
          let data = {
            apiMethod: 'user/RemindPassword',
            method: 'post',
            type: 'json',
            body: {
              Email: this.models.email,
            },
          };

          this.error = false;
          this.$store.dispatch('sendRequest', data).then((res) => {
            console.log('Remind', res);

            if (res.data.Status === 200) {
              this.success = true;

              setTimeout(()=>{
                this.resetComponent();
              }, 400);
            } else {
              this.error = true;

              if (res.data.Message) {
                this.errorMessage = res.data.Message;
              }
            }
          });
        }
      },
      resetComponent() {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();
      },
    },
  }
</script>

<style scoped>

</style>

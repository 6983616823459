import { render, staticRenderFns } from "./priceDynamics.vue?vue&type=template&id=163cecca&scoped=true&"
import script from "./priceDynamics.vue?vue&type=script&lang=js&"
export * from "./priceDynamics.vue?vue&type=script&lang=js&"
import style0 from "./priceDynamics.vue?vue&type=style&index=0&id=163cecca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "163cecca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/skipin/NetBeansProjects/Address/Address.Web/Assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('163cecca')) {
      api.createRecord('163cecca', component.options)
    } else {
      api.reload('163cecca', component.options)
    }
    module.hot.accept("./priceDynamics.vue?vue&type=template&id=163cecca&scoped=true&", function () {
      api.rerender('163cecca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "sources/vue/pages/Building/priceDynamics.vue"
export default component.exports
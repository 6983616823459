import { render, staticRenderFns } from "./spacesByType.vue?vue&type=template&id=004f8490&"
import script from "./spacesByType.vue?vue&type=script&lang=js&"
export * from "./spacesByType.vue?vue&type=script&lang=js&"
import style0 from "./spacesByType.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/skipin/NetBeansProjects/Address/Address.Web/Assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('004f8490')) {
      api.createRecord('004f8490', component.options)
    } else {
      api.reload('004f8490', component.options)
    }
    module.hot.accept("./spacesByType.vue?vue&type=template&id=004f8490&", function () {
      api.rerender('004f8490', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "sources/vue/pages/Building/spacesByType.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "popup-wrapper" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.showPopup
          ? _c(
              "div",
              {
                staticClass: "vue-popup__overlay",
                on: {
                  click: function($event) {
                    if ($event.target !== $event.currentTarget) {
                      return null
                    }
                    return _vm.cancel($event)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vue-popup popup popup--request",
                    on: {
                      click: function($event) {
                        if ($event.target !== $event.currentTarget) {
                          return null
                        }
                        return _vm.cancel($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "popup__block" }, [
                      _c("button", {
                        staticClass: "popup__close",
                        on: { click: _vm.togglePopup }
                      }),
                      _vm._v(" "),
                      _vm.message
                        ? _c("div", { staticClass: "popup__content" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.message) +
                                "\n          "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.confirmMessage
                        ? _c("div", [
                            _c("div", { staticClass: "popup__content" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.confirmMessage) +
                                  "\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "popup__footer" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "feedback__submit",
                                  attrs: { type: "button" },
                                  on: { click: _vm.affirm }
                                },
                                [_vm._v("Согласен")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "feedback__submit",
                                  attrs: { type: "button" },
                                  on: { click: _vm.cancel }
                                },
                                [_vm._v("Отменить")]
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.form
        ? _c("div", {
            ref: "formWrapper",
            domProps: { innerHTML: _vm._s(_vm.form) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
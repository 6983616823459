<template>
  <div class="block-single block-stages" v-if="groups && groups.length">
    <div class="title h3">Динамика строительства</div>

    <div class="construction-progress">
      <div class="construction-progress__tabs main-tabs">
        <div v-for="group in groups"
             @click="activeGroup = group"
             :class="{active: group.Id === activeGroup.Id}"
             class="construction-progress__tab tab-main">
          <span>{{ group.Name }} год</span>
        </div>
      </div>
      <div class="construction-progress__tabs children-tabs">
        <div v-for="child in activeGroup.Photos"
             @click="activeChild = child"
             :class="{active: child.Id === activeChild.Id}"
             class="construction-progress__tab tab-child">
          <span>{{ child.Name }}</span>
        </div>
      </div>


      <div class="construction-progress__photos" ref="photoContainer">
        <!--    <transition-group name="fade-in" tag="div" class="construction-progress__photos">-->
        <div v-for="item in photos"
             :key="item.Id"
             class="construction-progress__photo">
          <a :href="item.Photo" data-fancybox="construction-progress">
            <img :src="item.Photo" />
          </a>
        </div>

        <div class="construction-progress__photo" v-if="activeChild.More">
          <div class="load_more_photo" @click="loadMore">
            <span>
              <camera-icon></camera-icon>
              {{ `Еще ${activeChild.More} фото` }}
            </span>
          </div>
        </div>
        <!--    </transition-group>-->
      </div>
    </div>
  </div>
</template>

<script>
  import cameraIcon from '../../../icons/camera.svg';

  export default {
    name: "constructionProgress",
    components: {
      cameraIcon,
    },
    data() {
      return {
        groups: [],
        activeGroup: {
          Photos: [],
        },
        activeChild: {
          PhotoCount: 0,
          More: 0,
        },
        startCount: 7,
        photos: [],
      }
    },
    computed: {
      globalVars() {
        return this.$store.state.globalVars;
      },
      complexId() {
        return this.globalVars.complexId || this.getIdFromGallery();
      },
    },
    watch: {
      activeGroup: {
        deep: true,
        handler(item) {
          if (item && item.Photos) {
            this.activeChild = item.Photos[0];
          }
        }
      },
      activeChild: {
        deep: true,
        handler(item) {
          if (item && item.Id) {
            this.getMore(item);
            this.getPhotosInGroup(this.startCount);

            setTimeout(()=>{
              this.$refs.photoContainer.scrollLeft = 0;
            });
          }
        }
      }
    },
    mounted() {
      this.getPhotoGroups();
    },
    methods: {
      getPhotoGroups() {
        let data = {
          apiMethod: 'ConstructionComplex/GetPhotoGroupList',
          body: {
            complexId: this.complexId,
            photoSectionId: 2,
          },
        };

        this.$store.dispatch('sendRequest', data).then((res) => {
          // console.log('getPhotoGroups', res);

          if (res.data && res.data.length) {
            this.groups = res.data;

            this.activeGroup = this.groups[0];
          }
        });
      },
      getIdFromGallery() {
        let reg = /\/complex\/([0-9]+)\/bg\//;
        let link = document.querySelector('.block-info .complex-fancybox');

        if (link) {
          let href = link.getAttribute('href');
          let result = reg.exec(href);

          if (result.length > 1) {
            return parseInt(result[1]);
          }
        }
        return false;
      },
      getMore(item) {
        if (item.PhotoCount) {
          let more = item.PhotoCount - this.startCount;

          item.More = more > 0 ? more : 0;
        } else {
          item.More = 0;
        }
      },
      getPhotosInGroup(count = 0) {
        let data = {
          apiMethod: 'ConstructionComplex/GetPhotosByGroup',
          body: {
            complexId: this.complexId,
            photoSectionId: 2,
            pageNumber: 1,
            photoGroupId: this.activeChild.Id,
            pageSize: count,
          },
        };

        this.$store.dispatch('sendRequest', data).then((res) => {
          // console.log('getPhotosInGroup', res);

          if (res.data) {
            this.photos = res.data;
          }
        });
      },
      loadMore() {
        this.activeChild.More = 0;
        this.getPhotosInGroup();
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../../../../css_scss/scss/setup';

  .construction-progress {
    margin-top: 24px;
    &__tabs {
      display: flex;

      @include tablet {
        overflow-x: auto;
      }

      &.children-tabs {
        margin-top: 25px;
      }

    }

    &__tab {
      padding: 6px 12px;
      color: $c-black;
      transition: $transition;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 10px;
      }

      &.tab {
        &-main {
          font-style: normal;
          font-weight: 500;
          font-size: 13px;

          @include desktop {
            &:hover {
              background-color: #FDF5E4;
              color: $c-orange;
              transition: $transition;
            }
          }

          &.active {
            background-color: $c-orange;
            color: $c-white;
            transition: $transition;
          }

          span {
            @include mobile {
              white-space: nowrap;
            }
          }

        }

        &-child {
          color: $c-dark-grey;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          border-bottom: 2px solid transparent;

          @include desktop {
            &:hover {
              border-bottom-color: $c-orange;
              transition: $transition;
            }
          }

          &.active {
            color: $c-black;
            border-bottom-color: $c-orange;
            transition: $transition;
          }

        }
      }
    }

    &__photos {
      margin-top: 18px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 24px;

      @include mobile {
        display: flex;
        overflow-x: auto;
        margin-top: 12px;
      }
    }

    &__photo {
      cursor: pointer;
      display: flex;
      height: 7.69vw;
      border: 2px solid transparent;
      transition: all 0.4s;

      @include mobile {
        min-width: 82.5vw;
        height: 60vw;
        margin-right: 8px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        border-color: #F1BE48;
      }

    }

    .children-tabs {
      display: flex;
      flex-wrap: wrap;

      @include mobile {
        flex-wrap: nowrap;
      }

      span {
        white-space: nowrap;
      }

    }

    .load_more_photo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F5F7F9;
      color: #F1BE48;
      svg {
        width: 16px;
        margin-right: 8px;
      }

    }
  }
</style>

<style lang="scss">
  .fade-in-enter-active {
    transition: opacity .3s;
  }

  .fade-in-enter {
    opacity: 0;
  }
</style>
